import React, { useState } from 'react'
import { Barcode, BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning'
import { useEffect } from 'react'
import { BiScan } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchClientID, reset as authReset, resetClientID } from '../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'
import { reset, setClientId, fetchReferralDetails, confirmClientGPDR, resetCampaigns } from '../features/campaign/campaignSlice'
import { useSelector, useDispatch } from 'react-redux'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'

import { customToast } from '../components/CustomToast'
import Spinner from '../components/Spinner'

const QRreader = (props) => {
  const [isScannerActive, setIsScannerActive] = useState(false)
  const [detectedBarcodes, setDetectedBarcodes] = useState([])
  const [gpdr, setGpdr] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth <= 640
  const [hasJoinedCampaign, setHasJoinedCampaign] = useState(false)
  const [barcodes, setBarcodes] = useState([])
  const { isSuccess, joinedCampaignIsSuccess, isError, isLoading, userDetails, campaigns, message, clientId, referringCustomerID } = useSelector(
    (state) => state.campaign
  )
  const { clientID } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  //resets campaigns when component unmounts
  useEffect(() => {
    return () => {
      BarcodeScanner.removeAllListeners()
      dispatch(resetCampaigns())
      dispatch(resetClientID())
    }
  }, [dispatch])

  //error handling
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (joinedCampaignIsSuccess) {
      customToast.success('Successfully joined campaign!')
      dispatch(reset())
    }

    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, joinedCampaignIsSuccess])

  const resetClientData = () => {
    console.log('reset customer data runs')
    dispatch(reset())
    dispatch(resetClientID())
    dispatch(setClientId(''))
    dispatch(resetCampaigns())
  }

  function getClientID(url) {
    // Extract the part of the URL after the last '/'
    const code = url.split('/').pop()

    // Check if the extracted part is exactly 6 characters (digits or letters a-f)
    if (code && code.length === 6 && /^[0-9a-fA-F]{6}$/.test(code)) {
      return code
    }
    return null
  }

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions()
    return camera === 'granted' || camera === 'limited'
  }

  const scanQRCode = async () => {
    try {
      const granted = await requestPermissions()
      if (!granted) {
        console.error('Camera permission denied')
        return
      }

      const { barcodes } = await BarcodeScanner.scan()
      setBarcodes(barcodes)

      if (barcodes.length === 0) {
        navigate(-1)
        return
      }

      if (barcodes.length > 0) {
        const qrCodeData = barcodes[0].rawValue
        if (qrCodeData.startsWith('q.loyaltyclubplc')) {
          dispatch(fetchClientID(getClientID(qrCodeData)))
        }
      }
    } catch (error) {
      console.error('Error scanning QR code:', error)
      navigate(-1)
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined && Array.isArray(userDetails.campaignsJoined) && userDetails.campaignsJoined.length > 0) {
      setHasJoinedCampaign(true)
    } else {
      setHasJoinedCampaign(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.clients) {
      const client = userDetails.clients.find((c) => c.clientID === (clientId || clientID))
      if (client) {
        setGpdr(client.acceptedGPDR)
      }
    }
  }, [userDetails, clientId, clientID])

  useEffect(() => {
    // Check for query parameters from login/register page
    const params = new URLSearchParams(location.search)
    const clientId = params.get('clientId')
    const customerReferral = params.get('referral')

    if (clientId) {
      dispatch(setClientId(clientId))
    }

    if (customerReferral) {
      dispatch(fetchReferralDetails(customerReferral))
    }
  }, [location.search, dispatch])

  useEffect(() => {
    // Add this useEffect to trigger scan on mount
    scanQRCode()
  }, []) // Empty dependency array means this runs once on component mount

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (referringCustomerID === null) {
      // if referringCustomerID is null, then the user is not a referral, so they can join any campaign with enrolmentViaQr
      return campaign.enrolmentViaQr
    } else if (
      (referringCustomerID && !campaign.enrolmentViaQr && campaign.enrolmentViaIntroduction) || // new condition
      (referringCustomerID && campaign.enrolmentViaQr) // existing condition
    ) {
      return true
    } else {
      return false
    }
  })

  return (
    <div className='flex flex-col justify-center  md:min-h-0  ' style={{ minHeight: 'calc(100vh - 74px - 64px)' }}>
      <Helmet>
        <title>Reader | Loyalty Club PLC</title>
      </Helmet>
      {isLoading && <Spinner />}
      {campaigns.length >= 1 ? (
        <>
          <p className='text-xl mb-3 font-bold'>
            {filteredCampaigns.length} campaigns found for {campaigns[0]?.businessName}{' '}
          </p>
          <button
            className='btn'
            onClick={() => {
              document.getElementById('my-modal-6').checked = true
              navigate(-1) // Add navigation here
            }}>
            View Loyalty Campaigns
          </button>
        </>
      ) : (
        <h1 className='text-lg font-bold mt-8'>Scanning for Partner QR codes...</h1>
      )}
      <JoinPartnerCampaign
        navigate={navigate}
        clientId={clientId}
        clientID={clientID}
        referringCustomerID={referringCustomerID}
        resetClientData={resetClientData}
      />
    </div>
  )
}

export default QRreader
