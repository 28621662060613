import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { BarcodeScanner, BarcodeFormat, GoogleBarcodeScannerModuleInstallState } from '@capacitor-mlkit/barcode-scanning'
import { fetchClientID, reset as authReset, resetClientID } from '../features/auth/authSlice'
import { reset, setClientId, fetchReferralDetails, confirmClientGPDR, resetCampaigns } from '../features/campaign/campaignSlice'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'
import { customToast } from '../components/CustomToast'
import Spinner from '../components/Spinner'

const QRreaderAndroid = (props) => {
  const [data, setData] = useState('No result')
  const [gpdr, setGpdr] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [hasJoinedCampaign, setHasJoinedCampaign] = useState(false)
  const [isSupported, setIsSupported] = useState(false)

  const isMobile = windowWidth <= 640
  const { isSuccess, joinedCampaignIsSuccess, isError, isLoading, userDetails, campaigns, message, clientId, referringCustomerID } = useSelector(
    (state) => state.campaign
  )

  const { clientID } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const location = useLocation()

  //resets campaigns when component unmounts
  useEffect(() => {
    return () => {
      // Code here will run when the component unmounts
      dispatch(resetCampaigns())
      dispatch(resetClientID())
    }
  }, [dispatch])

  useEffect(() => {
    BarcodeScanner.isSupported().then((result) => {
      setIsSupported(result.supported)
    })
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (joinedCampaignIsSuccess) {
      customToast.success('Successfully joined campaign!')
      dispatch(reset())
    }

    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, joinedCampaignIsSuccess])

  function getClientID(url) {
    // Extract the part of the URL after the last '/'
    const code = url.split('/').pop()

    // Check if the extracted part is exactly 6 characters (digits or letters a-f)
    if (code && code.length === 6 && /^[0-9a-fA-F]{6}$/.test(code)) {
      return code
    }
    return null
  }

  useEffect(() => {
    console.log(data)
    // Check if 'data' begins with 'loyaltyclubplc'
    if (data.startsWith('q.loyaltyclubplc')) {
      dispatch(fetchClientID(getClientID(data)))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined && Array.isArray(userDetails.campaignsJoined) && userDetails.campaignsJoined.length > 0) {
      setHasJoinedCampaign(true)
    } else {
      setHasJoinedCampaign(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.clients) {
      const client = userDetails.clients.find((c) => c.clientID === (clientId || clientID))
      if (client) {
        setGpdr(client.acceptedGPDR)
      }
    }
  }, [userDetails, clientId, clientID])

  useEffect(() => {
    // Check for query parameters from login/register page
    const params = new URLSearchParams(location.search)
    const clientId = params.get('clientId')
    const customerReferral = params.get('referral')

    if (clientId) {
      dispatch(setClientId(clientId))
    }

    if (customerReferral) {
      dispatch(fetchReferralDetails(customerReferral))
    }
  }, [location.search, dispatch])

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   console.log('submit')
  // }

  // const onChange = (e) => {
  //   setGpdr(e.target.checked)

  //   let idToUse
  //   if (clientID && clientID.length === 24) {
  //     idToUse = clientID
  //   } else if (clientId && clientId.length === 24) {
  //     idToUse = clientId
  //   }

  //   if (idToUse) {
  //     console.log(e.target.checked, idToUse)
  //     dispatch(confirmClientGPDR({ gpdr: e.target.checked, idToUse }))
  //   } else {
  //     console.error('No valid idToUse found')
  //   }
  // }

  const scanQRCode = async () => {
    try {
      const granted = await requestPermissions()
      if (!granted) {
        console.error('Camera permission denied')
        return
      }

      const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()

      if (!available) {
        await BarcodeScanner.installGoogleBarcodeScannerModule()

        const listener = await BarcodeScanner.addListener('googleBarcodeScannerModuleInstallProgress', async (event) => {
          console.log('Installation progress:', event.progress)

          if (event.state === GoogleBarcodeScannerModuleInstallState.COMPLETED) {
            await listener.remove()
            scanBarcodes()
          } else if (event.state === GoogleBarcodeScannerModuleInstallState.FAILED) {
            await listener.remove()
            console.error('Google Barcode Scanner module installation failed')
          }
        })
      } else {
        scanBarcodes()
      }
    } catch (error) {
      console.error('Error scanning QR code:', error)
    }
  }

  const scanBarcodes = async () => {
    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.QrCode],
    })

    if (barcodes.length > 0) {
      const qrCodeData = barcodes[0].rawValue
      setData(qrCodeData)
    }
  }

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions()
    return camera === 'granted' || camera === 'limited'
  }

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (referringCustomerID === null) {
      // if referringCustomerID is null, then the user is not a referral, so they can join any campaign with enrolmentViaQr
      return campaign.enrolmentViaQr
    } else if (
      (referringCustomerID && !campaign.enrolmentViaQr && campaign.enrolmentViaIntroduction) || // new condition
      (referringCustomerID && campaign.enrolmentViaQr) // existing condition
    ) {
      return true
    } else {
      return false
    }
  })

  useEffect(() => {
    // Add this useEffect to trigger scan on mount
    scanQRCode()
  }, []) // Empty dependency array means this runs once on component mount

  return (
    <div className='flex flex-col justify-center md:min-h-0' style={{ minHeight: 'calc(100vh - 74px - 64px)' }}>
      <Helmet>
        <title>Reader | Loyalty Club PLC</title>
      </Helmet>
      {isLoading && <Spinner />}
      <div className='flex justify-center'>
        <button onClick={scanQRCode} className='btn btn-primary mb-4'>
          Scan QR Code Android
        </button>
      </div>
      {campaigns.length >= 1 ? (
        <>
          <p className='text-xl mb-3 font-bold'>
            {filteredCampaigns.length} campaigns found for {campaigns[0]?.businessName}{' '}
          </p>
          <button className='btn' onClick={() => (document.getElementById('my-modal-6').checked = true)}>
            View Loyalty Campaigns
          </button>
        </>
      ) : (
        <h1 className='text-lg font-bold'>Please Scan a Partner's QR code</h1>
      )}
      <JoinPartnerCampaign clientId={clientId} clientID={clientID} referringCustomerID={referringCustomerID} />
    </div>
  )
}

export default QRreaderAndroid
