import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { reset, fetchMyCampaigns, fetchCustomer, fetchStampCount, fetchRewardImage, fetchClientSocialLinks } from '../features/campaign/campaignSlice'
import { fetchLogoImage } from '../features/partner/partnerSlice'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { exchangeStampForTeds, exchangeRewardForTeds, buyStampForTeds, reset as tedsReset, resetTedsValues } from '../features/teds/tedsSlice'
import Spinner from '../components/Spinner'
import { fetchProfileDetails } from '../features/partner/partnerSlice'
import InfoTooltip from '../components/InfoTooltip'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import BackButton from '../components/BackButton'
import { customToast } from '../components/CustomToast'
import { BiGift } from 'react-icons/bi'
import { FaGifts } from 'react-icons/fa'
import { FaExchangeAlt } from 'react-icons/fa'
import { getRewardsMessage } from '../utils/getRewardMessage'
import { Helmet } from 'react-helmet-async'
import About from '../pages/About'
import CustomerFeedback from '../components/CustomerFeedback'
import SkeletonCampaign from '../components/SkeletonCampaign'

const Campaigns = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const clientIDFromURL = searchParams.get('clientID')
  const { isSuccess, isError, isLoading, message, myCampaigns, socialLinks, stampCountIsLoading, stampCountIsSuccess, userDetails } = useSelector(
    (state) => state.campaign
  )
  const isDataLoaded = !isLoading

  const { user } = useSelector((state) => state.auth)
  const { profileDetails } = useSelector((state) => state.partner)

  const {
    isSuccess: tedsIsSuccess,
    tedsAwarded,
    message: tedsMessage,
    stampBoughtIsLoading,
    stampSoldIsLoading,
    exchangeRewardIsLoading,
    rewardExchangedSuccess,
    stampBoughtIsSuccess,
    stampSoldIsSuccess,
    isError: tedsIsError,
  } = useSelector((state) => state.teds)

  // create state for ''currentCampaign"
  const [currentClientID, setCurrentClientID] = useState('')
  const campaignImages = useRef({})
  const partnerImages = useRef({})
  const campaignIcons = useRef({})
  const buySellSound = useRef(new Audio('/sounds/buy-sell.mp3'))
  const pressTimer = useRef(null)
  const [selectedStamp, setSelectedStamp] = useState(null)
  const [selectedReward, setSelectedReward] = useState(null)
  const [activeTab, setActiveTab] = useState('campaigns')
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isAboutActive, setIsAboutActive] = useState(false)
  const [debouncedLoading, setDebouncedLoading] = useState(false)
  const debounceTimeout = useRef(null)
  const [partnerImagesLoaded, setPartnerImagesLoaded] = useState(false)
  const [iconsLoaded, setIconsLoaded] = useState({})
  const [imageLoadingStates, setImageLoadingStates] = useState({})
  const googleReviewUrl = profileDetails?.googleMaps?.googleMapsLink
  const requestReviewAfter = profileDetails?.googleMaps?.requestReviewAfter
  const [newlyPurchasedStamp, setNewlyPurchasedStamp] = useState(null)
  const [sellingStamp, setSellingStamp] = useState(null)

  // Check if the user has already left a review for this client
  const hasLeftReview = userDetails?.clients?.find((client) => client.clientID === currentClientID)?.leftReview || false

  const numberOfVisits = userDetails?.clients?.find((client) => client.clientID === currentClientID)?.numberOfVisits || 0

  // Determine if we should show the CustomerFeedback component
  const shouldShowFeedback = !hasLeftReview && numberOfVisits >= requestReviewAfter

  const debounceLoading = (loading) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = setTimeout(() => {
      setDebouncedLoading(loading)
      debounceTimeout.current = null
    }, 300) // Adjust the timeout to fine-tune the debouncing effect
  }

  // Sort campaigns to move 'legacy' status to the bottom
  const sortedCampaigns = useMemo(() => {
    return [...myCampaigns].sort((a, b) => {
      if (a.status === 'legacy' && b.status !== 'legacy') return 1
      if (a.status !== 'legacy' && b.status === 'legacy') return -1
      return 0
    })
  }, [myCampaigns])

  //delay loading spinner so it displays consistently
  useEffect(() => {
    debounceLoading(isLoading)
  }, [isLoading])

  // Load partner images on page load
  useEffect(() => {
    const loadImages = async () => {
      const newLoadingStates = {}
      for (const clientID in partnerImages.current) {
        newLoadingStates[clientID] = false
        const img = new Image()
        img.onload = () => {
          setImageLoadingStates((prev) => ({ ...prev, [clientID]: true }))
        }
        img.onerror = () => {
          setImageLoadingStates((prev) => ({ ...prev, [clientID]: true }))
        }
        img.src = partnerImages.current[clientID]
      }
      setImageLoadingStates(newLoadingStates)
    }
    if (partnerImagesLoaded) {
      loadImages()
    } else {
    }
  }, [partnerImagesLoaded, partnerImages])

  // Fetch partner images
  useEffect(() => {
    if (userDetails && userDetails.clients && userDetails.clients.length > 0) {
      fetchPartnerImages()
    }
  }, [userDetails])

  const handleToggle = () => {
    setIsAboutActive(!isAboutActive)
    setActiveTab(isAboutActive ? 'campaigns' : 'about')
  }

  // get unique business names from my campaigns array
  const uniqueBusinessNamesWithClientID = useMemo(() => {
    // Create an array of objects containing businessName and clientID
    const businessNamesAndIDs = myCampaigns.map((campaign) => ({
      businessName: campaign.businessName,
      clientID: campaign.clientID,
    }))

    // Filter out duplicates based on the businessName
    const uniqueBusinessNamesAndIDs = businessNamesAndIDs.filter(
      (item, index, array) => array.findIndex((otherItem) => otherItem.businessName === item.businessName) === index
    )

    return uniqueBusinessNamesAndIDs
  }, [myCampaigns])

  // Reset state when there is a success or error
  useEffect(() => {
    if (stampBoughtIsSuccess || stampSoldIsSuccess) {
      buySellSound.current.play()
      customToast.success(tedsMessage)
      dispatch(resetTedsValues())
    }

    if (tedsIsSuccess) {
      customToast.success(tedsMessage)
      dispatch(tedsReset())
    }

    if (isError) {
      customToast.error(message.message)
      console.log('oh yeah??')
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, stampCountIsSuccess, tedsIsSuccess, tedsMessage, stampSoldIsSuccess, stampBoughtIsSuccess])

  // Reset state when there is an error
  useEffect(() => {
    if (tedsIsError) {
      if (tedsMessage === 'This campaign is no longer active, you can no longer buy stamps') {
        customToast.error(tedsMessage)
        console.log('oh yeah?? calling teds reset')
        dispatch(tedsReset())
      } else {
        document.getElementById('teds_error_modal').checked = true
      }
    }
    dispatch(tedsReset())
  }, [tedsIsError, dispatch, tedsMessage])

  // fetch customers campaigns
  useEffect(() => {
    dispatch(fetchMyCampaigns()).then(() => {
      dispatch(fetchCustomer())
      dispatch(fetchStampCount())
      dispatch(fetchClientSocialLinks())
    })
  }, [dispatch])

  // fetch profile details for about us page
  useEffect(() => {
    if (currentClientID) {
      dispatch(fetchProfileDetails(currentClientID))
    }
  }, [dispatch, currentClientID])

  // set currentClientID to the clientID of the businessName that was clicked
  useEffect(() => {
    if (myCampaigns.length > 0) {
      if (clientIDFromURL) {
        setCurrentClientID(clientIDFromURL)
      } else {
        setCurrentClientID(uniqueBusinessNamesWithClientID[0].clientID)
      }
    }
  }, [myCampaigns, uniqueBusinessNamesWithClientID, clientIDFromURL])

  // Fetch campaign icons
  useEffect(() => {
    myCampaigns.forEach((campaign) => {
      if (campaign.icon) {
        if (campaign.icon.includes('images/icons/')) {
          campaignIcons.current[campaign.icon] = campaign.icon
          setIconsLoaded((prevIconsLoaded) => ({
            ...prevIconsLoaded,
            [campaign.icon]: true,
          }))
        } else if (!campaignIcons.current[campaign.icon]) {
          dispatch(fetchRewardImage(campaign.icon)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
              campaignIcons.current = {
                ...campaignIcons.current,
                [campaign.icon]: result.payload,
              }
              setIconsLoaded((prevIconsLoaded) => ({
                ...prevIconsLoaded,
                [campaign.icon]: true,
              }))
            }
            setHasLoaded(true)
          })
        }
      }
    })
  }, [myCampaigns, dispatch])

  // fetch campaign images
  useEffect(() => {
    myCampaigns.forEach((campaign) => {
      if (campaign.image && !campaignImages.current[campaign.image]) {
        dispatch(fetchRewardImage(campaign.image)).then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            campaignImages.current = {
              ...campaignImages.current,
              [campaign.image]: result.payload,
            }
          }
        })
      }
    })
  }, [myCampaigns, dispatch])

  const fetchPartnerImages = async () => {
    console.log('Starting fetchPartnerImages')
    const promises = userDetails.clients.map(async (client) => {
      if (client.logo && !partnerImages.current[client.clientID]) {
        console.log(`Fetching logo for clientID: ${client.clientID}`)
        const result = await dispatch(fetchLogoImage(client.logo))
        if (result.meta.requestStatus === 'fulfilled') {
          console.log(`Logo fetched successfully for clientID: ${client.clientID}`)
          console.log('Logo Image URL:', result.payload)
          partnerImages.current = {
            ...partnerImages.current,
            [client.clientID]: result.payload,
          }
        } else {
          console.error(`Failed to fetch logo for clientID: ${client.clientID}`)
        }
      }
    })

    await Promise.all(promises)
    console.log('All partner images fetched, setting partnerImagesLoaded to true')
    setPartnerImagesLoaded(true)
  }

  // set currentClientID to the clientID of the businessName that was clicked
  const handleClick = (clientID) => {
    console.log('Clicked clientID:', clientID)
    setCurrentClientID(clientID)
  }

  // handles long press when buying/selling a stamp or reward
  const handleLongPressStart = (event, campaign) => {
    event.preventDefault()

    clearTimeout(pressTimer.current)
    pressTimer.current = window.setTimeout(() => {
      setSelectedStamp({
        tedsPerStamp: campaign.tedsPerStamp,
        tedsActive: typeof campaign.tedsActive !== 'undefined' ? campaign.tedsActive : false,
        campaignId: campaign._id,
        currentStamps: campaign.newStampCount || 0,
      })
      document.getElementById('modalToggle').checked = true
    }, 800)
  }

  const handleStampClick = (event, campaign) => {
    event.preventDefault()

    clearTimeout(pressTimer.current)
    pressTimer.current = window.setTimeout(() => {
      setSelectedStamp({
        tedsPerStamp: campaign.tedsPerStamp,
        tedsActive: typeof campaign.tedsActive !== 'undefined' ? campaign.tedsActive : false,
        campaignId: campaign._id,
        currentStamps: campaign.newStampCount || 0,
      })
      // Logic to open the modal on click
      document.getElementById('modalToggle').checked = true
    }, 800)
  }

  // clears timeout for long press on buy/sell stamp or rewardd
  const handleMouseUpOrLeave = () => {
    // Clear the timer when the mouse is released or leaves the element
    clearTimeout(pressTimer.current)
  }

  // handles stamp sale
  const handleSellStamp = (selectedStamp) => {
    setSellingStamp(selectedStamp.campaignId)

    // Delay the actual selling action to allow animation to complete
    setTimeout(() => {
      const stampData = {
        campaignId: selectedStamp.campaignId,
      }
      dispatch(exchangeStampForTeds(stampData)).then(() => {
        setTimeout(() => setSellingStamp(null), 100)
      })
    }, 1000) // Changed to match animation duration (1.5s) plus a small buffer
  }

  // handles reward sale
  const handleExchangeReward = (selectedReward) => {
    const stampData = {
      campaignId: selectedReward.campaignId,
    }
    dispatch(exchangeRewardForTeds(stampData))
  }
  // handles buy new stamp
  const handleBuyNewStamp = (selectedStamp) => {
    const stampData = {
      campaignId: selectedStamp.campaignId,
    }

    dispatch(buyStampForTeds(stampData)).then(() => {
      // Set the newly purchased stamp
      setNewlyPurchasedStamp(selectedStamp.campaignId)
      // Clear the animation after 1 second
      setTimeout(() => setNewlyPurchasedStamp(null), 1000)

      // Find the relevant campaign again after the update
      const campaign = myCampaigns.find((camp) => camp._id === selectedStamp.campaignId)

      // Check if stamps acquired equal campaign goal
      if (campaign && campaign.newStampCount === campaign.goal - 1) {
        console.log('Reward condition met!')
        dispatch(fetchCustomer())
      } else {
        console.log('Reward condition not met')
      }

      // Now fetch the updated stamp count
      dispatch(fetchStampCount())
    })
  }

  // handles reward sale
  const RewardExchangeStart = (event, campaign, isClick = false) => {
    event.preventDefault()

    if (isClick) {
      setSelectedReward({
        tedsPerReward: campaign.tedsPerStamp * campaign.goal,
        campaignId: campaign._id,
        businessName: campaign.businessName,
        rewardName: campaign.rewardName,
      })
      document.getElementById('rewardModalToggle').checked = true
    } else {
      clearTimeout(pressTimer.current)
      pressTimer.current = window.setTimeout(() => {
        setSelectedReward({
          tedsPerReward: campaign.tedsPerStamp * campaign.goal,
          campaignId: campaign._id,
          businessName: campaign.businessName,
          rewardName: campaign.rewardName,
        })
        document.getElementById('rewardModalToggle').checked = true
      }, 800)
    }
  }

  const resetTeds = () => {
    dispatch(resetTedsValues())
    document.getElementById('rewardModalToggle').checked = false
  }

  return (
    <>
      <Helmet>
        <title>Campaigns | Loyalty Club PLC</title>
      </Helmet>

      {/* New Animated Campaigns Header Banner */}
      {uniqueBusinessNamesWithClientID.length > 0 && (
        <div className='relative w-[calc(100vw-2rem)] left-1/2 -translate-x-1/2 bg-gradient-to-r from-orange-400 to-yellow-400 p-4 -mt-4 mb-8 overflow-hidden shadow-2xl rounded-3xl min-h-[200px] md:min-h-[200px] '>
          {/* Scanning Beam Effect */}
          <div className='absolute inset-0 bg-gradient-to-b from-orange-200/20 via-yellow-300/30 to-transparent opacity-25 animate-scan-beam' />

          {/* Geometric Elements */}
          <div className='absolute inset-0 opacity-40 mix-blend-soft-light'>
            {/* Animated background elements */}
            <div className='absolute inset-0 bg-[length:400%_400%] bg-gradient-to-r from-blue-400/20 via-transparent to-purple-400/20 animate-gradient-flow' />
            <div className='absolute inset-0 bg-noise opacity-10' />
          </div>

          {/* Added container with horizontal padding */}
          <div className='container max-w-7xl mx-auto px-4'>
            {/* Main Content Container */}
            <div className='relative z-10 h-full flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0'>
              {/* Left Logo Section */}
              <div className='relative w-28 h-28 md:w-48 md:h-48 flex-shrink-0 flex items-center justify-center'>
                {partnerImages.current[currentClientID] && (
                  <div className='relative group transform transition-transform duration-700 hover:scale-[1.02]'>
                    <div className='absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400 rounded-xl opacity-0 group-hover:opacity-30 blur-2xl transition-opacity duration-300' />
                    <img
                      src={partnerImages.current[currentClientID]}
                      alt='Business Logo'
                      className='h-28 w-28 md:h-32 md:w-32 rounded-lg object-cover border-4 border-white/30 shadow-2xl transform transition-transform duration-500 group-hover:scale-110 animate-float'
                    />
                  </div>
                )}
              </div>

              {/* Center Text Section - Modified for mobile dropdown */}
              <div className='text-white space-y-2 text-center max-w-2xl md:max-w-3xl md:mx-12'>
                {/* Mobile Dropdown */}
                <div className='md:hidden w-full mb-4'>
                  <select
                    value={currentClientID}
                    onChange={(e) => handleClick(e.target.value)}
                    className='select select-lg w-full max-w-xs bg-white/10 backdrop-blur-md text-white border-white/20 hover:border-white/40 transition-all duration-300'>
                    {uniqueBusinessNamesWithClientID.map(({ businessName, clientID }) => (
                      <option key={clientID} value={clientID} className='text-gray-800'>
                        {businessName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Mobile Title */}
                <div className='md:hidden text-center mb-4'>
                  <h2 className='text-2xl font-bold'>
                    <span className='text-xl block  bg-clip-text text-orange-500'>
                      {activeTab === 'campaigns' ? 'Active Loyalty Programs' : 'About Us'}
                    </span>
                  </h2>
                </div>

                {/* Desktop Title */}
                <h2 className='hidden md:block text-2xl md:text-4xl font-bold leading-tight'>
                  <span className='inline-block bg-clip-text text-transparent bg-gradient-to-r from-white to-amber-100'>
                    {uniqueBusinessNamesWithClientID.find((b) => b.clientID === currentClientID)?.businessName}
                  </span>
                  <br />
                  <span className='text-xl md:text-3xl block mt-2 bg-clip-text text-orange-500'>
                    {activeTab === 'campaigns' ? 'Active Loyalty Programs' : 'About Us'}
                  </span>
                </h2>
              </div>
            </div>
          </div>

          {/* Animated border elements */}
          <div className='absolute inset-0 border-4 border-white/5 rounded-2xl pointer-events-none' />
          <div className='absolute inset-0 border-[12px] border-transparent [mask:linear-gradient(45deg,transparent_10%,white_50%,transparent_90%)] animate-border-shine' />
        </div>
      )}

      {debouncedLoading ? (
        <SkeletonCampaign />
      ) : isDataLoaded ? (
        <>
          {myCampaigns.length === 0 && (
            <>
              <div className='flex mb-4'>
                <BackButton />
              </div>
              <div className='bg-gray-200 p-4 rounded-lg'>
                <h2 className='text-2xl font-bold'>Your Loyalty Dashboard is a Blank Canvas!</h2>
                <p className='text-lg'>
                  Ahoy there! It appears your loyalty dashboard is as empty as a pirate ship with no rum. 🏴‍☠️ But worry not, you're just a scan away
                  from a treasure trove of rewards.
                </p>

                <h3 className='text-lg font-bold mt-2'>Two Ways to Set Sail on your Loyalty Journey:</h3>
                <div className='space-y-4'>
                  <div>
                    <h4 className='font-bold'>1. Use Our Treasure Map 🗺️</h4>
                    <Link to='/partners-map' className='btn btn-primary my-2'>
                      Find Partners Near You
                    </Link>
                    <p className='text-sm text-gray-600'>Discover all participating businesses in your area!</p>
                  </div>

                  <div>
                    <h4 className='font-bold'>2. Visit Any Partner Store</h4>
                    <ul className='list-disc list-inside ml-4'>
                      <li>Look for our loyalty sign at the counter</li>
                      <li>Scan their QR code with your phone</li>
                      <li>Start collecting rewards immediately!</li>
                    </ul>
                  </div>
                </div>

                <p className='text-lg mt-4'>Either way, you'll soon be swimming in a sea of points, discounts, and other valuable doubloons!</p>
              </div>
            </>
          )}
          <div className='flex flex-col md:flex-row bg-[#f1f2f5] w-full'>
            {myCampaigns.length >= 1 && (
              <>
                <div className='hidden md:block'>
                  <ul className='flex sticky top-20 menu md:w-56 mr-4'>
                    {uniqueBusinessNamesWithClientID.map(({ businessName, clientID }) => {
                      const client = userDetails.clients.find((client) => client.clientID === clientID)
                      const lastVisitDate = new Date(client?.dateOfLastVisit)
                      const isValidDate = lastVisitDate instanceof Date && !isNaN(lastVisitDate)

                      return (
                        <li key={clientID}>
                          <button
                            className={`flex flex-col justify-start items-start text-left w-56 shadow p-2 ${
                              currentClientID === clientID ? 'bg-white opacity-90 text-gray-700' : 'bg-gray-200 text-gray-700 hover:bg-gray-100'
                            }`}
                            onClick={(e) => {
                              e.preventDefault()
                              handleClick(clientID)
                            }}>
                            <div className='flex items-center'>
                              <div className='w-10 h-10 mr-2 overflow-hidden'>
                                {partnerImages.current[clientID] ? (
                                  imageLoadingStates[clientID] ? (
                                    <img
                                      src={partnerImages.current[clientID]}
                                      alt={`${businessName} logo`}
                                      className='w-full h-full object-cover shadow-3xl border-white align-center mask mask-squircle'
                                    />
                                  ) : (
                                    <div className='w-full h-full bg-gray-300 animate-pulse-subtle rounded-full'></div>
                                  )
                                ) : (
                                  <div className='w-full h-full bg-gray-300 rounded-full flex items-center justify-center'>
                                    <div className='w-8 h-8 bg-gray-400 rounded-full'></div>
                                  </div>
                                )}
                              </div>
                              <div className='flex flex-col'>
                                <div className='font-bold text-left mb-1'>{businessName}</div>
                                {client && isValidDate && (
                                  <span className='-mt-1 text-xs text-gray-500'>Last Visit: {lastVisitDate.toLocaleDateString()}</span>
                                )}
                              </div>
                            </div>
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className='md:hidden flex justify-center mb-6'>
                  <div className='relative inline-block text-left'></div>
                </div>
              </>
            )}

            <div className='flex flex-col w-full justify-start'>
              {/* Display partner LOGO*/}
              <div className='flex items-center justify-center'>
                {/* Removed logo image */}

                {/* Toggle between About and Campaigns */}
                {myCampaigns.length >= 1 ? (
                  <div className='flex items-center justify-center'>
                    <div className='inline-flex rounded-full bg-gray-300 p-2 drop-shadow-xl'>
                      <button
                        className={`px-6 py-2 rounded-full ${!isAboutActive ? 'bg-orange-500 text-white' : 'bg-gray-300 text-gray-700 '}`}
                        onClick={handleToggle}
                        disabled={!isAboutActive}>
                        Campaigns
                      </button>
                      <button
                        className={`px-6 py-2 rounded-full ${isAboutActive ? 'bg-orange-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                        onClick={handleToggle}
                        disabled={isAboutActive}>
                        About
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>

              {activeTab === 'campaigns' ? (
                <div>
                  {sortedCampaigns
                    .filter((campaign) => currentClientID === '' || campaign.clientID === currentClientID)
                    .map((campaign) => (
                      // Loyalty Card
                      <div key={campaign._id} className='flex flex-col my-8'>
                        <div className='bg-white px-4 rounded-lg shadow-lg pt-8 w-full relative'>
                          <div className='mb-5 flex flex-col items-center'>
                            {/* Status badge with integrated tooltip for legacy campaigns */}
                            <div className='absolute top-2 right-2'>
                              <div className='flex items-center justify-center align-middle'>
                                <div className={`badge ${campaign.status === 'active' ? 'badge-success' : 'badge-warning'} text-white`}>
                                  {campaign.status === 'active' ? (
                                    'Active'
                                  ) : (
                                    <div className='flex items-center'>
                                      <span>Campaign Paused by Vendor</span>
                                      <div
                                        className='campaign-tooltip ml-1'
                                        data-tip='You can still sell your existing stamps, but new stamps cannot be purchased for this campaign.'>
                                        <AiOutlineInfoCircle className='text-white' />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {campaignImages.current[campaign.image] && (
                              <img
                                src={campaignImages.current[campaign.image]}
                                alt={`${campaign.campaignName}`}
                                className='h-1/5 w-1/4 sm:w-1/5 md:w-1/6 shadow-2xl border-white align-center mask mask-squircle'
                              />
                            )}
                            <div className='flex flex-col justify-center flex-grow'>
                              <div className='font-bold text-3xl text-center my-1'>{campaign.campaignName}</div>
                              <div className='text-center justify-center my-3 text-gray-400 text-sm'>
                                Value per stamp {campaign.tedsPerStamp} TEDS
                              </div>
                            </div>
                          </div>
                          <div className='grid grid-flow-row grid-cols-5 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-9 font-bold items-center gap-4 justify-items-center'>
                            {[...Array(campaign.goal + 1)].map((_, index) => {
                              // Check if the current stamp is filled
                              const isStampFilled = index < campaign.newStampCount

                              // Determine the next empty stamp spot
                              const isNextEmptyStampSpot = index === campaign.newStampCount

                              // Determine if this is the last filled stamp spot for selling actions
                              const isLastFilledStampSpot = index === campaign.newStampCount - 1

                              // Render filled stamp or the next empty spot with the spinner if loading for buying
                              if (isStampFilled) {
                                if (stampSoldIsLoading && campaign._id === selectedStamp?.campaignId && isLastFilledStampSpot) {
                                  // Replace the last filled stamp with a spinner when selling
                                  return (
                                    <div
                                      key={index}
                                      className='w-14 h-14 lg:h-16 lg:w-16 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400'>
                                      <div className='flex flex-col justify-center items-center'>
                                        <img className='w-5 h-5' src='/images/loadingSpinner.svg' alt='Loading...' />
                                        <p className='text-xs text-gray-400'>Selling stamp...</p>
                                      </div>
                                    </div>
                                  )
                                }
                                // Regular stamp icon with animation
                                return (
                                  <div
                                    key={index}
                                    className={`
                                      w-12 h-12 lg:h-16 lg:w-16 
                                      rounded-md shadow-2xl 
                                      border-2 border-black 
                                      my-icon 
                                      ${isStampFilled ? 'pulse' : ''} 
                                      flex items-center justify-center 
                                      overflow-hidden 
                                      cursor-pointer 
                                      hover:scale-105 
                                      transition-transform
                                      ${newlyPurchasedStamp === campaign._id && index === campaign.newStampCount - 1 ? 'animate-new-stamp' : ''}
                                      ${sellingStamp === campaign._id && index === campaign.newStampCount - 1 ? 'animate-sell-stamp' : ''}
                                    `}
                                    onMouseDown={(e) => handleLongPressStart(e, campaign)}
                                    onMouseUp={handleMouseUpOrLeave}
                                    onMouseLeave={handleMouseUpOrLeave}
                                    onClick={(e) => handleStampClick(e, campaign)}
                                    onTouchStart={(e) => handleLongPressStart(e, campaign)}
                                    onTouchEnd={handleMouseUpOrLeave}>
                                    {iconsLoaded[campaign.icon] ? (
                                      <img src={campaignIcons.current[campaign.icon]} alt='Stamp' className='w-full h-full object-contain p-1' />
                                    ) : (
                                      <div className='flex items-center justify-center w-full h-full'>
                                        <span className='loading loading-spinner loading-md'></span>
                                      </div>
                                    )}
                                  </div>
                                )
                              } else if (isNextEmptyStampSpot && stampBoughtIsLoading && campaign._id === selectedStamp?.campaignId) {
                                // Show spinner in the next empty stamp spot when buying
                                return (
                                  <div
                                    key={index}
                                    className='w-14 h-14 lg:h-16 lg:w-16 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400'>
                                    <div className='flex flex-col justify-center items-center'>
                                      <img className='w-5 h-5' src='/images/loadingSpinner.svg' alt='Loading...' />
                                      <p className='text-xs text-gray-400'>Purchasing stamp...</p>
                                    </div>
                                  </div>
                                )
                              } else if (index === campaign.goal) {
                                // Show "Free" for the last spot
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400 cursor-pointer hover:scale-105 transition-transform'
                                    onMouseDown={(e) => handleLongPressStart(e, campaign)}
                                    onMouseUp={handleMouseUpOrLeave}
                                    onMouseLeave={handleMouseUpOrLeave}
                                    onClick={(e) => handleStampClick(e, campaign)}
                                    onTouchStart={(e) => handleLongPressStart(e, campaign)}
                                    onTouchEnd={handleMouseUpOrLeave}>
                                    Free
                                  </div>
                                )
                              } else {
                                // Empty stamp spot
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md border-gray-300 shadow-2xl border-dotted border-2 flex justify-center items-center text-gray-400 cursor-pointer hover:scale-105 transition-transform select-none'
                                    onMouseDown={(e) => handleLongPressStart(e, campaign)}
                                    onMouseUp={handleMouseUpOrLeave}
                                    onMouseLeave={handleMouseUpOrLeave}
                                    onClick={(e) => handleStampClick(e, campaign)}
                                    onTouchStart={(e) => handleLongPressStart(e, campaign)}
                                    onTouchEnd={handleMouseUpOrLeave}
                                    style={{ WebkitTapHighlightColor: 'transparent' }}>
                                    {index + 1}
                                  </div>
                                )
                              }
                            })}
                          </div>

                          {hasLoaded && (
                            <div className='flex justify-center mt-8 font-bold'>
                              {getRewardsMessage(campaign.newStampCount, campaign.goal, campaign.rewardName)}
                            </div>
                          )}

                          <div className='flex justify-center mt-3'>
                            <Link
                              to={`/campaign/${campaign._id}?clientID=${currentClientID}`}
                              className='mr-3 w-36 justify-center flex flex-nowrap btn text-xs btn-sm btn-primary mt-4'>
                              View
                            </Link>
                          </div>

                          <div className='flex justify-between pt-5'>
                            {campaign.rewardsRedeemed >= 1 ? (
                              <div className='flex p-3 text-xxs md:text-lg font-bold badge badge-outline badge-primary mb-3'>
                                <BiGift />
                                &nbsp;{' '}
                                {campaign.rewardsRedeemed > 1
                                  ? campaign.rewardsRedeemed + ' Rewards redeemed'
                                  : campaign.rewardsRedeemed + ' Reward redeemed'}
                              </div>
                            ) : null}
                            {campaign.readyToRedeem >= 1 ? (
                              <div
                                className='flex text-xxs md:text-lg font-bold badge p-3 badge-outline badge-secondary mb-3 cursor-pointer badge-hover'
                                onMouseDown={(e) => RewardExchangeStart(e, campaign)}
                                onMouseUp={handleMouseUpOrLeave}
                                onMouseLeave={handleMouseUpOrLeave}
                                onTouchStart={(e) => RewardExchangeStart(e, campaign)}
                                onTouchEnd={handleMouseUpOrLeave}
                                onClick={(e) => {
                                  // Clear any existing press timer
                                  clearTimeout(pressTimer.current)
                                  pressTimer.current = null

                                  // Trigger reward exchange immediately
                                  setSelectedReward({
                                    tedsPerReward: campaign.tedsPerStamp * campaign.goal,
                                    campaignId: campaign._id,
                                    businessName: campaign.businessName,
                                    rewardName: campaign.rewardName,
                                  })
                                  document.getElementById('rewardModalToggle').checked = true
                                }}>
                                <FaGifts /> &nbsp;
                                {campaign.readyToRedeem} Reward{campaign.readyToRedeem > 1 ? "'s" : ''} ready to redeem
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className='w-full'>
                  <About profileDetails={profileDetails} clientID={currentClientID} socialLinks={socialLinks} userDetails={userDetails} />
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {/* Hidden checkbox to control modal visibility - STAMP BUY/SELL  */}
      <input type='checkbox' id='modalToggle' className='modal-toggle' />

      {/* Modal structure triggered by the checkbox STAMP BUY/SELL  */}
      <div className='modal modal-bottom sm:modal-middle custom-zindex' id='my_modal_8'>
        <div className='modal-box relative pb-20 sm:pb-16'>
          {/* Add close button */}
          <label htmlFor='modalToggle' className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>

          {selectedStamp?.tedsActive ? (
            <>
              {/* Moved heading outside image row */}
              <h3 className='font-bold text-xl mb-4 text-center'>Buy or Sell a Stamp?</h3>
              <div className='flex items-center justify-center my-3 gap-4'>
                {/* Stamp image with label */}
                {selectedStamp && campaignIcons.current && myCampaigns.find((c) => c._id === selectedStamp.campaignId)?.icon && (
                  <div className='flex flex-col items-center'>
                    <div className='w-20 h-20'>
                      {iconsLoaded[myCampaigns.find((c) => c._id === selectedStamp.campaignId).icon] ? (
                        <img
                          src={campaignIcons.current[myCampaigns.find((c) => c._id === selectedStamp.campaignId).icon]}
                          alt='Stamp'
                          className='w-full h-full mask mask-squircle p-1 bg-base-100 border-2 border-white shadow-2xl object-contain'
                        />
                      ) : (
                        <div className='animate-pulse bg-gray-200 rounded-lg w-full h-full mask mask-squircle'></div>
                      )}
                    </div>
                    <div className='font-bold text-sm mt-1 text-center'>1 X Loyalty Stamp</div>
                  </div>
                )}
                <FaExchangeAlt size={35} className='mx-2' />
                {/* TEDS value display */}
                <div className='flex flex-col items-center p-2 border-2 border-gray-200 rounded-2xl bg-white'>
                  <div className='w-20 h-20 aspect-square overflow-hidden'>
                    <img
                      src='/images/TEDSLogo-sm.png'
                      alt='TEDS'
                      className='w-full h-full mask mask-squircle p-1 bg-base-100 border-2 border-white shadow-2xl object-cover'
                    />
                  </div>
                  <div className='mt-0'>
                    <span className='font-bold text-md px-4 py-2 rounded-full inline-flex items-center'>
                      <img src='/images/gold-coin.svg' alt='' className='w-4 h-4 mr-1.5 object-contain' />
                      {(selectedStamp.tedsPerStamp * 0.9).toFixed(2)} TEDS
                    </span>
                  </div>
                </div>
              </div>

              <h3 className='text-gray-400 text-sm'> Current Balance : {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                {selectedStamp?.currentStamps > 0 ? (
                  <>
                    Would you like to sell a stamp for
                    <span className='font-bold'>
                      {' '}
                      {(selectedStamp.tedsPerStamp * 0.9).toFixed(2)} TEDS?{' '}
                      <InfoTooltip
                        dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the platform.`}
                        position={'tooltip-left'}
                      />{' '}
                    </span>
                    <br />
                    or purchase a new stamp for <span className='font-bold'>{selectedStamp.tedsPerStamp} TEDS?</span>
                  </>
                ) : (
                  <>
                    Would you like to purchase a new stamp for <span className='font-bold'>{selectedStamp.tedsPerStamp} TEDS?</span>
                  </>
                )}
              </p>
            </>
          ) : (
            <>
              <h3 className='font-bold text-lg'>Stamp Not Available for Trade</h3>
              <p className='py-4'>
                Unfortunately, this stamp is currently not available for trading through the TEDS system. Please check back later or explore other
                stamps that might be available.
              </p>
            </>
          )}
          <div className='modal-action fixed bottom-0 left-0 right-0 w-full bg-white p-4 flex flex-row justify-center gap-3'>
            {selectedStamp?.tedsActive && (
              <div className='w-full px-4 sm:px-0 sm:w-auto flex justify-center gap-3'>
                {selectedStamp?.currentStamps > 0 && (
                  <label
                    htmlFor='modalToggle'
                    className='btn btn-primary flex-1 sm:w-[180px] md:text-lg text-sm font-semibold'
                    onClick={() => handleSellStamp(selectedStamp)}>
                    SELL STAMP
                  </label>
                )}
                {user?.wallet?.tedsBalance < selectedStamp?.tedsPerStamp ? (
                  <Tippy content='You have Insufficient TEDS balance to buy a new stamp' placement='top' arrow={true} delay={[200, 0]}>
                    <span className='disabled-wrapper flex-1 sm:w-[180px]'>
                      <label htmlFor='modalToggle' className='btn btn-secondary btn-disabled w-full text-sm md:text-lg font-semibold' disabled>
                        BUY NEW STAMP
                      </label>
                    </span>
                  </Tippy>
                ) : (
                  <label
                    htmlFor='modalToggle'
                    className='btn btn-secondary flex-1 sm:w-[180px] text-sm md:text-lg font-semibold'
                    onClick={() => handleBuyNewStamp(selectedStamp)}>
                    BUY NEW STAMP
                  </label>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Hidden checkbox to control modal visibility - Reward BUY/SELL  */}
      <input type='checkbox' id='rewardModalToggle' className='modal-toggle' />

      {/* Modal structure triggered by the checkbox Reward BUY/SELL */}
      <div className='modal' id='my_modal_9'>
        <div className='modal-box'>
          {exchangeRewardIsLoading ? (
            <div className='text-center'>
              <div className='flex justify-center'>
                <img src='/images/spinner.png' alt='loading...' className='w-10 h-10 animate-spin' />
              </div>
              <p className='text-black font-bold text-lg my-4'>Exchanging Reward for TEDS, Please wait...</p>
            </div>
          ) : rewardExchangedSuccess ? (
            <div className='text-center'>
              <div className='flex justify-center'>
                <img src='/images/chinese-coins.svg' alt='success' className='w-20 h-20' />
              </div>
              <p className='text-black font-bold text-lg my-4'>
                Congratulations! Your reward has been successfully exchanged for {tedsAwarded} TEDS.
              </p>
              <p className='text-black text-md my-4'>Your new balance is {user?.wallet?.tedsBalance} TEDS.</p>
              <div className='modal-action'>
                <label onClick={() => resetTeds()} htmlFor='rewardModalToggle' className='btn'>
                  OK
                </label>
              </div>
            </div>
          ) : (
            <>
              <h3 className='font-bold text-lg'>Exchange Reward For TEDS?</h3>
              <div className='flex items-center justify-center gap-4 my-3'>
                {/* Reward image display */}
                {selectedReward && campaignImages.current && myCampaigns.find((c) => c._id === selectedReward.campaignId)?.image && (
                  <div className='flex flex-col items-center'>
                    <div className='w-20 h-20 aspect-square overflow-hidden'>
                      {campaignImages.current[myCampaigns.find((c) => c._id === selectedReward.campaignId).image] ? (
                        <img
                          src={campaignImages.current[myCampaigns.find((c) => c._id === selectedReward.campaignId).image]}
                          alt='Reward'
                          className='w-full h-full mask mask-squircle p-1 bg-base-100 border-2 border-white shadow-2xl object-cover'
                        />
                      ) : (
                        <div className='animate-pulse bg-gray-200 rounded-lg w-full h-full mask mask-squircle'></div>
                      )}
                    </div>
                    <div className='font-bold text-md mt-1 text-center'>{selectedReward.rewardName}</div>
                  </div>
                )}
                <FaExchangeAlt size={35} className='mx-2' />

                {/* TEDS logo container */}
                <div className='flex flex-col items-center p-2 border-2 border-gray-200 rounded-3xl bg-white'>
                  <div className='w-20 h-20 aspect-square overflow-hidden'>
                    <img
                      src='/images/TEDSLogo-sm.png'
                      alt='TEDS'
                      className='w-full h-full mask mask-squircle p-1 bg-base-100 border-2 border-white shadow-2xl object-cover'
                    />
                  </div>
                  <div className=''>
                    <span className='font-bold text-md px-4 py-2 rounded-full  inline-flex items-center'>
                      <img src='/images/gold-coin.svg' alt='' className='w-4 h-4 mr-1.5 object-contain' />
                      {Math.round(selectedReward?.tedsPerReward * 0.9)} TEDS
                    </span>
                  </div>
                </div>
              </div>

              <h3 className='text-gray-400 text-sm'>TEDS Wallet Balance: {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                Are you sure you want to exchange your <span className='font-bold'>{selectedReward?.rewardName}</span> from{' '}
                {selectedReward?.businessName} for{' '}
                <span className='font-bold'>
                  {selectedReward?.tedsPerReward * 0.9} TEDS?
                  <InfoTooltip
                    dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the loyalty platform.`}
                  />
                </span>
              </p>
              <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4' role='alert'>
                <p className='font-bold'>Attention</p>
                <p>Exchanging this reward is irreversible and will result in the permanent loss of the reward. </p>
              </div>
              <p className='text-gray-600 text-xs mb-4'></p>
              <div className='modal-action'>
                <label htmlFor='rewardModalToggle' className='btn'>
                  Cancel
                </label>
                <button className='btn btn-primary' onClick={() => handleExchangeReward(selectedReward)}>
                  Exchange
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {shouldShowFeedback && googleReviewUrl && (
        <div className='pb-40'>
          <CustomerFeedback googleReviewUrl={googleReviewUrl} clientID={currentClientID} requestReviewAfter={requestReviewAfter} />
        </div>
      )}
      {/* TEDS Error Modal */}
      <input type='checkbox' id='teds_error_modal' className='modal-toggle' />
      <div className='modal custom-zindex' role='dialog'>
        <div className='modal-box relative'>
          <label htmlFor='teds_error_modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h2 className='font-bold text-2xl mb-4'>Transaction Unable to Complete</h2>
          <p className='mb-4 text-left'>
            We apologize, but we're unable to complete this transaction at the moment. The loyalty program is experiencing a temporary technical
            issue.
          </p>
          <p className='mb-4 text-left'>Here's what you need to know:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Your stamp is safe and will remain valid.</li>
            <li className='text-left mb-1'>We're working to resolve this issue as quickly as possible.</li>
            <li className='text-left mb-1'>Please try again in 24-48 hours.</li>
          </ul>
          <p className='mb-4 text-left'>If the issue persists, please contact our customer support team.</p>
          <p className='mt-6 font-bold text-left'>
            We appreciate your patience and continued loyalty. Thank you for being a valued member of our program!
          </p>
          <div className='modal-action'>
            <label htmlFor='teds_error_modal' className='modal-backdrop btn btn-primary'>
              Close
            </label>
          </div>
        </div>
      </div>

      {/* Add animation styles */}
      <style>{`
        @keyframes gradient-flow {
          0% { background-position: 0% 50% }
          50% { background-position: 100% 50% }
          100% { background-position: 0% 50% }
        }
        @keyframes float {
          0%, 100% { transform: translateY(0) rotate(-1deg); }
          50% { transform: translateY(-10px) rotate(1deg); }
        }
        @keyframes border-shine {
          from { mask-position: 200% 0; }
          to { mask-position: -200% 0; }
        }
        .animate-gradient-flow {
          animation: gradient-flow 12s ease infinite;
        }
        .animate-float {
          animation: float 6s ease-in-out infinite;
        }
        .animate-border-shine {
          animation: border-shine 8s linear infinite;
        }
        .bg-noise {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 600 600' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
        }
        @keyframes scan-beam {
          0% { transform: translateY(-100%); opacity: 0; }
          50% { opacity: 0.4; }
          100% { transform: translateY(100%); opacity: 0; }
        }
        .animate-scan-beam {
          animation: scan-beam 8s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        /* Custom styles for the select dropdown */
        .select {
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 1rem center;
          background-size: 1.5em;
          padding-right: 2.5rem;
        }

        .select option {
          background-color: white;
          color: #374151;
          padding: 0.5rem;
        }

        /* Add smooth transition effects */
        .select {
          transition: all 0.3s ease;
        }

        .select:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        }

        .select:focus {
          outline: none;
          ring: 2px;
          ring-offset: 2px;
          ring-white;
        }
      `}</style>
    </>
  )
}

export default Campaigns
