import React, { useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchClientID, reset as authReset, resetClientID } from '../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'
import { reset, setClientId, fetchReferralDetails, confirmClientGPDR, resetCampaigns } from '../features/campaign/campaignSlice'
import { useSelector, useDispatch } from 'react-redux'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'

import { customToast } from '../components/CustomToast'
import Spinner from '../components/Spinner'

const QRreader = (props) => {
  const [data, setData] = useState('No result')
  const [gpdr, setGpdr] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth <= 640
  const [hasJoinedCampaign, setHasJoinedCampaign] = useState(false)

  const { isSuccess, joinedCampaignIsSuccess, isError, isLoading, userDetails, campaigns, message, clientId, referringCustomerID } = useSelector(
    (state) => state.campaign
  )

  const { clientID } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const location = useLocation()

  //resets campaigns when component unmounts
  useEffect(() => {
    return () => {
      // Code here will run when the component unmounts
      dispatch(resetCampaigns())
      dispatch(resetClientID())
    }
  }, [dispatch])

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (joinedCampaignIsSuccess) {
      customToast.success('Successfully joined campaign!')
      dispatch(reset())
    }

    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, joinedCampaignIsSuccess])

  const resetClientData = () => {
    console.log('reset customer data runs')
    dispatch(reset())
    dispatch(resetClientID())
    dispatch(setClientId(''))
    dispatch(resetCampaigns())
    setData('No result')
  }

  function getClientID(url) {
    // Extract the part of the URL after the last '/'
    const code = url.split('/').pop()

    // Check if the extracted part is exactly 6 characters (digits or letters a-f)
    if (code && code.length === 6 && /^[0-9a-fA-F]{6}$/.test(code)) {
      return code
    }
    return null
  }

  useEffect(() => {
    console.log(data)
    // Check if 'data' begins with 'loyaltyclubplc'
    if (data.startsWith('q.loyaltyclubplc')) {
      dispatch(fetchClientID(getClientID(data)))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined && Array.isArray(userDetails.campaignsJoined) && userDetails.campaignsJoined.length > 0) {
      setHasJoinedCampaign(true)
    } else {
      setHasJoinedCampaign(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.clients) {
      const client = userDetails.clients.find((c) => c.clientID === (clientId || clientID))
      if (client) {
        setGpdr(client.acceptedGPDR)
      }
    }
  }, [userDetails, clientId, clientID])

  useEffect(() => {
    // Check for query parameters from login/register page
    const params = new URLSearchParams(location.search)
    const clientId = params.get('clientId')
    const customerReferral = params.get('referral')

    if (clientId) {
      dispatch(setClientId(clientId))
    }

    if (customerReferral) {
      dispatch(fetchReferralDetails(customerReferral))
    }
  }, [location.search, dispatch])

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   console.log('submit')
  // }

  // const onChange = (e) => {
  //   setGpdr(e.target.checked)

  //   let idToUse
  //   if (clientID && clientID.length === 24) {
  //     idToUse = clientID
  //   } else if (clientId && clientId.length === 24) {
  //     idToUse = clientId
  //   }

  //   if (idToUse) {
  //     console.log(e.target.checked, idToUse)
  //     dispatch(confirmClientGPDR({ gpdr: e.target.checked, idToUse }))
  //   } else {
  //     console.error('No valid idToUse found')
  //   }
  // }
  const filteredCampaigns = campaigns.filter((campaign) => {
    if (referringCustomerID === null) {
      // if referringCustomerID is null, then the user is not a referral, so they can join any campaign with enrolmentViaQr
      return campaign.enrolmentViaQr
    } else if (
      (referringCustomerID && !campaign.enrolmentViaQr && campaign.enrolmentViaIntroduction) || // new condition
      (referringCustomerID && campaign.enrolmentViaQr) // existing condition
    ) {
      return true
    } else {
      return false
    }
  })

  return (
    <div className='flex flex-col justify-center  md:min-h-0  '>
      <Helmet>
        <title>Reader | Loyalty Club PLC</title>
      </Helmet>
      {/* <div className='flex mb-8'>
        <BackButton />
      </div> */}
      {isLoading && <Spinner />}{' '}
      <div className=' flex justify-center'>
        <div className='md:w-1/2 md:h-1/2 w-full h-full  relative mx-0'>
          <QrReader
            className=''
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text)
              }

              if (!!error) {
                console.info(error)
              }
            }}
            style={{ width: '100%', margin: 0 }}
            constraints={{
              facingMode: 'environment',
            }}
          />
          {/* Corner Guides */}
          <div
            className='absolute border-t-8 border-l-8 border-primary pulse'
            style={{
              width: '10%',
              height: '10%',
              top: isMobile ? 20 : 110,
              left: isMobile ? 60 : 50,
            }}></div>
          <div
            className='absolute border-t-8 border-r-8 border-primary pulse'
            style={{
              width: '10%',
              height: '10%',
              top: isMobile ? 20 : 110,
              right: isMobile ? 60 : 50,
            }}></div>
          <div
            className='absolute border-b-8 border-l-8 border-primary pulse'
            style={{
              width: '10%',
              height: '10%',
              bottom: isMobile ? 20 : 110,
              left: isMobile ? 60 : 50,
            }}></div>
          <div
            className='absolute border-b-8 border-r-8 border-primary pulse'
            style={{
              width: '10%',
              height: '10%',
              bottom: isMobile ? 20 : 110,
              right: isMobile ? 60 : 50,
            }}></div>
        </div>
      </div>
      {campaigns.length >= 1 ? (
        <>
          <p className='text-xl mb-3 font-bold'>
            {filteredCampaigns.length} campaigns found for {campaigns[0]?.businessName}{' '}
          </p>
          <button className='btn' onClick={() => (document.getElementById('my-modal-6').checked = true)}>
            View Loyalty Campaigns
          </button>
        </>
      ) : (
        <h1 className='text-lg font-bold'> Please Scan a Partners QR code</h1>
      )}
      <JoinPartnerCampaign
        clientId={clientId}
        clientID={clientID}
        referringCustomerID={referringCustomerID}
        resetClientData={resetClientData}
        disableNavigation
      />
    </div>
  )
}

export default QRreader
