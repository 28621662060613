import { FaSignInAlt, FaSignOutAlt, FaUser, FaStamp } from 'react-icons/fa'
import React, { useState, useEffect } from 'react'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../features/auth/authSlice'
import { clearCampaignState } from '../features/campaign/campaignSlice'
import { clearMessageState } from '../features/message/messageSlice'
import UserDropdown from './UserDropdown'

function Header() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 800)
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(clearCampaignState())
    dispatch(clearMessageState())
    navigate('/login')
    console.log('successfully navigated away')
  }

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerHeight < 800)
      setIsLandscape(window.innerWidth > window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const headerStyle = {
    paddingTop: isLandscape ? '0px' : 'var(--safe-area-inset-top)',
    height: isLandscape ? '60px' : 'calc(60px + var(--safe-area-inset-top))',
    zIndex: 5000,
  }

  return (
    <div style={headerStyle} className='flex justify-between items-center py-2 bg-base-100 border-b border-gray-200 stickyHeader custom-zindex'>
      {/* Logo */}
      <div className='ml-3 pt-2'>
        <Link to='/' className='normal-case text-xl'>
          <img className={`w-48`} src='/images/logo.svg' alt='Logo' />
        </Link>
      </div>
      <div className='flex flex-row mr-1'>
        {/* Register Button */}
        {!user && (
          <Link
            className='mr-1 btn btn-primary text-white border-none px-2 md:px-4 flex-col justify-center md:flex-row flex items-center'
            to='/register'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaUser />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Register</p>
          </Link>
        )}

        {/* Login/Logout Buttons */}
        {user ? (
          <UserDropdown user={user} onLogout={onLogout} />
        ) : (
          <Link to='/login' className='btn btn-primary border-none mr-3 md:px-4 flex-col justify-center md:flex-row flex items-center'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaSignInAlt />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Login</p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Header
