import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MessageList from '../components/messages/MessageList'
import { getMoreCustomerMessages, resetMessagesState } from '../features/message/messageSlice'

import { Helmet } from 'react-helmet-async'
import { App as CapacitorApp } from '@capacitor/app'
import MessageSubject from '../components/messages/MessageSubject'
import MessageBody from '../components/messages/MessageBody'
import { BiArrowBack } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

const Messages = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const { messages } = useSelector((state) => state.message)

  const [lastMessageID, setLastMessageID] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [showMessageBody, setShowMessageBody] = useState(!isMobile)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleBackButton = () => {
      if (isMobile && showMessageBody) {
        setShowMessageBody(false)
      } else if (!showMessageBody) {
        navigate(-1)
      }
    }

    CapacitorApp.addListener('backButton', handleBackButton)

    return () => {
      CapacitorApp.removeAllListeners()
    }
  }, [navigate, location.pathname, isMobile, showMessageBody])

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true)
        await dispatch(getMoreCustomerMessages(lastMessageID))
      } catch (error) {
        console.error('Error fetching messages:', error)
      } finally {
        setIsLoading(false)
        setIsInitialLoad(false)
      }
    }

    if (isInitialLoad || lastMessageID !== null) {
      fetchMessages()
    }
  }, [dispatch, lastMessageID, isInitialLoad])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
      setShowMessageBody(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleListItemClick = () => {
    if (isMobile) {
      setShowMessageBody(true)
    }
  }

  const handleBackButtonClick = () => {
    if (isMobile) {
      setShowMessageBody(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetMessagesState())
    }
  }, dispatch)

  return (
    <>
      <Helmet>
        <title>Messages | Loyalty Club PLC</title>
      </Helmet>
      {isMobile && showMessageBody && (
        <button className='btn btn-square btn-primary btn-outline mb-4 flex' onClick={handleBackButtonClick}>
          <BiArrowBack size={30} />
        </button>
      )}
      <div className='flex'>
        {(!showMessageBody || !isMobile) && (
          <MessageList
            messages={messages}
            onListItemClick={handleListItemClick}
            setLastMessageID={setLastMessageID}
            isInitialLoad={isInitialLoad}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
        {showMessageBody && (
          <div className={`md:flex flex-col md:w-8/12 w-full mb-24 ${!showMessageBody && 'hidden'}`}>
            <MessageSubject onBackButtonClick={handleBackButtonClick} />
            <MessageBody />
          </div>
        )}
      </div>
    </>
  )
}

export default Messages
