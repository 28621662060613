import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { getUserDetails } from '../features/auth/authSlice'
import { getCustomerTEDSTransactions, fetchTedsWallet, reset } from '../features/teds/tedsSlice'
import SkeletonRow from '../components/SkeletonRow'

const TEDSWallet = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  // Calculate the total TEDS balance
  const totalTedsBalance = user.wallet.madTedsBalance + user.wallet.tedsBalance

  const {
    tedsTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.teds)

  const tedsToPounds = (teds) => (teds / 10).toFixed(2) // Convert TEDS to pounds

  useEffect(() => {
    if (isError) {
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    dispatch(getCustomerTEDSTransactions({ limit: 5 }))
    dispatch(fetchTedsWallet())
  }, [dispatch])

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='card md:w-1/2 w-full my-4 mx-auto bg-white'>
        <div className='card-body'>
          <div className='flex justify-center mb-4'>
            <img src='/images/TEDSLogo-sm.png' alt='TEDS Logo' className='w-32 h-32 object-contain drop-shadow-lg teds-logo' />
          </div>
          <div className='flex justify-center'>
            <h2 className='card-title mb-4'>TEDS Wallet</h2>
          </div>
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <div className='flex-1'>
              <div className='flex flex-col'>
                <p className='text-sm'>Available Balance</p>
                <p className='text-lg font-bold'>{totalTedsBalance.toFixed(1)} TEDS</p>
                <div className='mt-8'>
                  <p className='text-sm'>
                    Approx. <span>£{tedsToPounds(totalTedsBalance)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-4 mt-4 md:mt-0'></div>
          </div>
          <div className='divider'></div>
          <div className='mb-2'>
            <h3 className='card-title'>Recent Transactions</h3>
          </div>
          <div className='overflow-x-auto'>
            <table className='table w-full mt-3'>
              <thead>
                <tr>
                  <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4 min-w-[120px]' style={{ position: 'static' }}>
                    From
                  </th>
                  <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4 min-w-[120px]'>To</th>
                  <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4 min-w-[120px]'>TEDS Earned</th>
                  <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4 min-w-[120px]'>Type</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  Array.from({ length: 5 }).map((_, index) => <SkeletonRow key={index} columns={4} />)
                ) : transactions && transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <tr key={transaction._id}>
                      <td className='whitespace-nowrap' style={{ position: 'static' }}>
                        {transaction.fromUser.userName}
                      </td>
                      <td className='whitespace-nowrap'>{transaction.toUser.userName}</td>
                      <td className={`whitespace-nowrap ${transaction.transactionType === 'stamp-purchase' ? 'text-green-500' : 'text-red-500'}`}>
                        {transaction.transactionType === 'stamp-purchase' ? '+' : '-'}
                        {transaction.tedsAmount} TEDS
                      </td>
                      <td className='whitespace-nowrap'>{transaction.transactionType === 'stamp-purchase' ? 'Stamp Purchased' : 'Stamp Sold'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='4'>No recent transactions found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default TEDSWallet
