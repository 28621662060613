import React, { useEffect, useState, useRef, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { fetchPartnerMarkers, fetchLogoImage, fetchPartnerSocialLinks, resetMapPartner } from '../features/partner/partnerSlice'
import { fetchProfileDetails } from '../features/partner/partnerSlice'
import { resetClientID } from '../features/auth/authSlice'
import { resetCampaigns, reset, setClientId } from '../features/campaign/campaignSlice'
import { useSelector, useDispatch } from 'react-redux'
import IconMapper, { categoryIcons, getCategoryFromBusinessType } from '../components/IconMapper'
import MapFilter from '../components/MapFilter'
import { FaSearch } from 'react-icons/fa'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/Modal'
import About from '../pages/About'
import SkeletonRow from '../components/SkeletonRow'
import PartnersMap from '../components/PartnersMap'

const center = [52.5958, -1.6419] // Set initial map center

const AllPartnersMap = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { partners, noPartnersFound, isLoading, isSuccess, logoImageUrl, socialLinks, imageIsLoading, profileDetails } = useSelector(
    (state) => state.partner
  )

  console.log(profileDetails, 'profile details')

  const [selectedPartner, setSelectedPartner] = useState(null)
  const [containerStyle, setContainerStyle] = useState({
    width: '100%',
    height: '500px',
    zIndex: 1,
  })
  const [activeCategory, setActiveCategory] = useState(null)
  const [showJoinCampaign, setShowJoinCampaign] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(12)
  const [mapCenter, setMapCenter] = useState(center)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const [showRecommendModal, setShowRecommendModal] = useState(false)

  // Add this new state at the beginning of your component
  const [hasSearched, setHasSearched] = useState(false)

  // // accounting for the notch on IOS
  // const defaultPadding = 0
  // const defaultPaddingMap = 150 // or whatever value you prefer for desktop
  // const topPadding = `max(${defaultPadding}px, calc(var(--safe-area-inset-top, 0px) + ${defaultPadding}px))`
  // const topPaddingMap = `max(${defaultPaddingMap}px, calc(var(--safe-area-inset-top, 0px) + ${defaultPaddingMap}px))`

  const [showAboutModal, setShowAboutModal] = useState(false)

  // Add a new state to track loading
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)

  const resetClientData = () => {
    console.log('reset customer data runs')
    dispatch(reset())
    dispatch(resetClientID())
    dispatch(setClientId(''))
    dispatch(resetCampaigns())
  }

  useEffect(() => {
    // Function to get computed padding
    const getComputedPadding = () => {
      const element = document.querySelector('.top-padding-element')
      if (element) {
        const computedStyle = window.getComputedStyle(element)
        return computedStyle.marginTop // Note: changed from paddingTop to marginTop
      }
      return null
    }

    // Log the computed padding
    console.log('🚀 ~ AllPartnersMap ~ Computed topPadding:', getComputedPadding())

    // Optional: Set up a resize listener to log changes
    const handleResize = () => {
      console.log('🚀 ~ AllPartnersMap ~ Computed topPadding (after resize):', getComputedPadding())
    }
    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Define this near the top of your component, after your state definitions
  const debouncedFetchPartnerMarkers = useDebounce((bounds) => {
    dispatch(fetchPartnerMarkers(bounds))
  }, 1000)

  const mapRef = useRef(null)
  const timeoutRef = useRef(null)

  function useDebounce(callback, delay) {
    const timeoutRef = useRef(null)

    const debouncedFunction = useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          callback(...args)
        }, delay)
      },
      [callback, delay]
    )

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    return debouncedFunction
  }

  const checkForVisibleMarkers = useCallback(() => {
    // Add early return if component is unmounting
    if (!mapRef.current) return

    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      // Add additional null check here
      if (!mapRef.current) return

      const bounds = mapRef.current.getBounds()
      const visibleMarkers = partners.filter(
        (partner) =>
          partner.location &&
          partner.location.coordinates &&
          bounds.contains(L.latLng(partner.location.coordinates[1], partner.location.coordinates[0]))
      )

      if (visibleMarkers.length === 0) {
        setShowRecommendModal(true)
      } else {
        setShowRecommendModal(false)
      }
    }, 7000)
  }, [partners, setShowRecommendModal, isSuccess])

  // useEffect for checking visible markers after partners are loaded
  useEffect(() => {
    if (isSuccess && !isLoading && mapRef.current) {
      checkForVisibleMarkers()
    }
  }, [isSuccess, isLoading, partners, checkForVisibleMarkers, mapRef])

  const handleSearch = useCallback(async (query) => {
    if (query.length < 3) return
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error fetching search results:', error)
    }
  }, [])

  const debouncedHandleSearch = useDebounce(handleSearch, 1000)

  useEffect(() => {
    if (searchQuery.length >= 3) {
      debouncedHandleSearch(searchQuery)
    } else {
      setSearchResults([])
    }
  }, [searchQuery, debouncedHandleSearch])

  const handleSearchResultSelect = useCallback(
    (result) => {
      const newLocation = [parseFloat(result.lat), parseFloat(result.lon)]
      setSearchResults([])
      setSearchQuery('')
      setHasSearched(true) // Set this to true when a search is performed
      if (mapRef.current) {
        mapRef.current.setView(newLocation, 14)
        console.log('Map view updated, partners will be fetched')
        // Fetch partners for the new location
        const newBounds = {
          north: newLocation[0] + 0.1,
          south: newLocation[0] - 0.1,
          east: newLocation[1] + 0.1,
          west: newLocation[1] - 0.1,
          zoom: 14,
        }
        debouncedFetchPartnerMarkers(newBounds)
      }
    },
    [setSearchResults, setSearchQuery, setHasSearched, mapRef, debouncedFetchPartnerMarkers]
  )

  useEffect(() => {
    return () => {
      dispatch(resetCampaigns())
    }
  }, [dispatch])

  function BoundsHandler({ onBoundsChanged }) {
    const map = useMap()

    useEffect(() => {
      if (!map) return

      const handleMove = () => {
        // Add check to ensure map is still mounted
        if (!map) return

        const bounds = map.getBounds()
        const zoom = map.getZoom()
        onBoundsChanged({
          north: bounds.getNorth(),
          south: bounds.getSouth(),
          east: bounds.getEast(),
          west: bounds.getWest(),
          zoom,
        })
      }

      map.on('moveend', handleMove)

      // Clean up event listener
      return () => {
        if (map) {
          map.off('moveend', handleMove)
        }
      }
    }, [map, onBoundsChanged])

    return null
  }

  // Modify the useEffect hook for geolocation
  useEffect(() => {
    const handleSuccess = (position) => {
      const newLocation = [position.coords.latitude, position.coords.longitude]
      setUserLocation(newLocation)
      setMapCenter(newLocation) // Update map center
      if (mapRef.current) {
        mapRef.current.setView(newLocation, zoomLevel) // Immediately update map view
      }
    }

    const handleError = (error) => {
      console.error('Error occurred while fetching geolocation: ', error)
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }, [])

  // Modify handleBoundsChanged to call checkForVisibleMarkers
  const handleBoundsChanged = useCallback(
    (newBounds) => {
      if (newBounds.zoom >= 2) {
        debouncedFetchPartnerMarkers(newBounds)
        checkForVisibleMarkers() // Add this line
      }
    },
    [debouncedFetchPartnerMarkers, checkForVisibleMarkers]
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setContainerStyle({ width: '100%', height: '82%' })
      } else {
        setContainerStyle({ width: '100%', height: '82%' })
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const viewCampaigns = () => {
    if (selectedPartner && selectedPartner.clientID) {
      setShowJoinCampaign(true)
    }
  }

  const viewAboutUsPage = () => {
    // First set loading state and open modal
    setIsLoadingProfile(true)
    setTimeout(() => {
      const modalCheckbox = document.getElementById('about-modal')
      if (modalCheckbox) {
        modalCheckbox.checked = true
      }
    }, 0)
    setShowAboutModal(true)

    // Then fetch the profile details
    if (selectedPartner && selectedPartner.clientID) {
      dispatch(fetchProfileDetails(selectedPartner.clientID))
        .then(() => {
          setIsLoadingProfile(false)
        })
        .catch(() => {
          setIsLoadingProfile(false)
        })
    }
  }

  const navigateToPartner = (lat, lng) => {
    const url = `https://www.openstreetmap.org/directions?engine=osrm_car&route=${userLocation[0]},${userLocation[1]};${lat},${lng}`
    window.open(url, '_blank')
  }

  const handleMarkerClick = (partner) => {
    dispatch(resetMapPartner())
    dispatch(resetCampaigns())
    setSelectedPartner(partner)
    dispatch(fetchPartnerSocialLinks(partner.clientID))
    if (partner.businessLogo) {
      dispatch(fetchLogoImage(partner.businessLogo))
    }

    if (mapRef.current) {
      mapRef.current.setView([partner.location.coordinates[1], partner.location.coordinates[0]], 14)
    }
  }

  const createCustomIcon = (businessType) => {
    const iconUrl = IconMapper(businessType)
    return L.divIcon({
      className: 'custom-icon',
      html: `
        <div style="
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        ">
          <img src="${iconUrl}" alt="${businessType}" style="width: 20px; height: 20px;" />
        </div>
      `,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    })
  }

  // Add this useEffect to monitor state changes
  useEffect(() => {
    console.log('Modal state:', {
      showAboutModal,
      hasProfileDetails: !!profileDetails,
      selectedPartner,
    })
  }, [showAboutModal, profileDetails, selectedPartner])

  // Add cleanup for timeoutRef in component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      // Clear map reference on unmount
      mapRef.current = null
    }
  }, [])

  const getMapStyle = () => {
    if (window.innerWidth < 768) {
      return {
        width: '100%',
        height: 'calc(100vh - 180px)', // Adjust for mobile header/search/filter height
        zIndex: 0,
      }
    }
    return {
      width: '100%',
      height: 'calc(100vh - 180px)', // Adjust for desktop header/search/filter height
      zIndex: 0,
    }
  }

  return (
    <div className='flex flex-col h-screen w-full map-parent -mt-5 overflow-hidden'>
      {/* Header section */}
      <div
        className='fixed left-0 right-0 bg-white shadow-md z-20'
        style={{
          top: 'env(safe-area-inset-top, 0px)',
        }}>
        <div className='mx-auto md:pt-28 pt-24 pb-2'>
          <div className='relative mx-8 custom-zindex-less3'>
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder='Search for a location...'
              className='w-full p-3 pr-10 mr-4 text-base bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
              style={{
                fontSize: '16px',
                '-webkit-text-size-adjust': '100%',
                '-webkit-tap-highlight-color': 'transparent',
              }}
            />
            <FaSearch className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500' />
          </div>
        </div>
        <MapFilter activeCategory={activeCategory} setActiveCategory={setActiveCategory} categoryIcons={categoryIcons} />
      </div>

      {/* Map container */}
      <div
        className='fixed left-0 right-0 z-10'
        style={{
          top: 'calc(210px + env(safe-area-inset-top, 0px))',
          bottom: '55px',
        }}>
        <PartnersMap
          mapRef={mapRef}
          containerStyle={{
            width: '100%',
            height: '100%',
            position: 'relative', // Ensure zoom controls position relative to this container
          }}
          mapCenter={mapCenter}
          zoomLevel={zoomLevel}
          handleBoundsChanged={handleBoundsChanged}
          BoundsHandler={BoundsHandler}
          partners={partners}
          activeCategory={activeCategory}
          handleMarkerClick={handleMarkerClick}
          imageIsLoading={imageIsLoading}
          logoImageUrl={logoImageUrl}
          socialLinks={socialLinks}
          viewCampaigns={viewCampaigns}
          viewAboutUsPage={viewAboutUsPage}
          navigateToPartner={navigateToPartner}
          userLocation={userLocation}
        />
      </div>

      {showJoinCampaign && selectedPartner && (
        <JoinPartnerCampaign
          businessName={selectedPartner.businessName}
          resetClientData={resetClientData}
          clientId={selectedPartner.clientID}
          setShowJoinCampaign={setShowJoinCampaign}
        />
      )}

      {showRecommendModal && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center custom-zindex'>
          <div className='bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative'>
            <button onClick={() => setShowRecommendModal(false)} className='btn btn-sm btn-circle absolute right-2 top-2'>
              ✕
            </button>
            <h2 className='text-2xl font-bold mb-4 text-center'>No Businesses Found</h2>
            <p className='mb-4 text-center'>
              We couldn't find any businesses in this area. Help us grow our network by referring your favorite establishments
            </p>
            <div className='relative bg-gradient-to-r from-orange-400 to-yellow-400 p-4 rounded-xl overflow-hidden shadow-2xl'>
              {/* Scanning Beam Effect */}
              <div className='absolute inset-0 bg-gradient-to-b from-orange-200/20 via-yellow-300/30 to-transparent opacity-25 animate-scan-beam' />

              {/* Geometric Elements */}
              <div className='absolute inset-0 opacity-40 mix-blend-soft-light'>
                <div className='absolute inset-0 bg-[length:400%_400%] bg-gradient-to-r from-blue-400/20 via-transparent to-purple-400/20 animate-gradient-flow' />
                <div className='absolute inset-0 bg-noise opacity-10' />
              </div>

              {/* Main Content */}
              <div className='relative z-10 flex items-center space-x-4'>
                <img
                  src='/images/TEDSLogo-sm.png'
                  className='h-24 w-24 object-contain transform transition-transform duration-500 animate-float'
                  alt='TEDS Logo'
                />
                <div className='text-white'>
                  <p className='font-bold text-2xl mb-1'>Earn a Reward!</p>
                  <p>
                    Recommend a business and receive <span className='text-lg font-bold'>250 TEDS coins</span> when they join!
                  </p>
                </div>
              </div>

              {/* Animated border elements */}
              <div className='absolute inset-0 border-4 border-white/5 rounded-2xl pointer-events-none' />
              <div className='absolute inset-0 border-[12px] border-transparent [mask:linear-gradient(45deg,transparent_10%,white_50%,transparent_90%)] animate-border-shine' />
            </div>

            <div className='flex flex-col space-y-3 mt-6'>
              <button
                className='w-full px-4 py-2 btn btn-primary'
                onClick={() => {
                  navigate('/refer-a-partner')
                  setShowRecommendModal(false)
                }}>
                Recommend a Partner
              </button>
              <button className='w-full px-4 py-2 btn btn-outline btn-secondary' onClick={() => setShowRecommendModal(false)}>
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      )}
      {searchResults.length > 0 && (
        <div className='absolute left-0 right-0 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto custom-zindex top-36'>
          {searchResults.map((result) => (
            <div
              key={result.place_id}
              onClick={() => handleSearchResultSelect(result)}
              className='p-3 hover:bg-gray-100 cursor-pointer border-b border-gray-100 last:border-b-0'>
              <p className='font-semibold text-sm'>{result.display_name.split(',')[0]}</p>
              <p className='text-xs text-gray-500'>{result.display_name.split(',').slice(1).join(',').trim()}</p>
            </div>
          ))}
        </div>
      )}

      {showAboutModal && (
        <Modal id='about-modal' onClose={() => setShowAboutModal(false)}>
          {isLoadingProfile ? (
            <div className='w-full'>
              <h2 className='text-2xl font-bold mb-6'>Business Details</h2>
              <table className='w-full'>
                <tbody>
                  <SkeletonRow columns={2} />
                  <SkeletonRow columns={2} />
                  <SkeletonRow columns={2} />
                  <SkeletonRow columns={2} />
                </tbody>
              </table>
            </div>
          ) : (
            <About
              clientID={selectedPartner?.clientID}
              socialLinks={socialLinks}
              profileDetails={profileDetails}
              logoUrl={logoImageUrl}
              businessName={selectedPartner?.businessName}
              isFromMap={true}
            />
          )}
        </Modal>
      )}

      {/* Add required animations */}
      <style>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        @keyframes shine {
          to { transform: translateX(200%); }
        }
        .animate-float {
          animation: float 4s ease-in-out infinite;
        }
        .animate-shine {
          animation: shine 3s ease-in-out infinite;
          transform: translateX(-100%);
        }
        @keyframes scan-beam {
          0%, 100% { transform: translateY(-100%); }
          50% { transform: translateY(100%); }
        }
        .animate-scan-beam {
          animation: scan-beam 4s ease-in-out infinite;
        }
        @keyframes gradient-flow {
          0%, 100% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
        }
        .animate-gradient-flow {
          animation: gradient-flow 8s ease infinite;
        }
      `}</style>
    </div>
  )
}

export default AllPartnersMap
