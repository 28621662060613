import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { customToast } from '../components/CustomToast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getCustomerTEDSTransactions, reset } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import SkeletonRow from '../components/SkeletonRow'
const TEDSTransactions = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    tedsTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.teds)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getCustomerTEDSTransactions(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4 mt-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a transaction by name'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md  mr-2'
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Date</th>
              <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Transaction From</th>
              <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Transaction To</th>
              <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>TEDS Amount</th>
              <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Type</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              Array.from({ length: 6 }).map((_, index) => <SkeletonRow key={index} columns={5} />)
            ) : !Array.isArray(transactions) || transactions.length === 0 ? (
              <tr>
                <td colSpan='5'>No Transactions found.</td>
              </tr>
            ) : (
              transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={transaction.id}>
                  <td className='table-optimized'>
                    {new Date(transaction.createdAt).toLocaleDateString()}
                    <br />
                    <span className='badge  badge-sm text-xxxs md:text-xs'>
                      {new Date(transaction.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                    </span>
                  </td>
                  <td className='table-optimized'>{transaction.fromUser.userName}</td>
                  <td className='table-optimized'>{transaction.toUser.userName}</td>
                  <td className={`table-optimized  ${transaction.transactionType === 'stamp-purchase' ? 'text-red-500' : 'text-green-500'}`}>
                    {transaction.transactionType === 'stamp-purchase' ? '-' : '+'}
                    {transaction.tedsAmount} TEDS
                  </td>
                  <td className='table-optimized'>
                    {(() => {
                      switch (transaction.transactionType) {
                        case 'stamp-purchase':
                          return 'Stamp Purchased for TEDS'
                        case 'reward-exchange':
                          return 'Reward Sold for TEDS'
                        case 'stamp-exchange':
                          return 'Stamp Sold for TEDS'
                        default:
                          return transaction.transactionType.charAt(0).toUpperCase() + transaction.transactionType.slice(1)
                      }
                    })()}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default TEDSTransactions
