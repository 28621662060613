import React from 'react'
import IconMapper from './IconMapper'
import MapSocialLinks from './MapSocialLinks'

const BusinessPopup = ({ businessName, businessType, logoImageUrl, imageIsLoading, onViewCampaigns, onViewAboutUsPage, onNavigate, socialLinks }) => {
  return (
    <div className='bg-white px-4 pb-4 rounded-lg max-w-sm'>
      <div className='avatar flex justify-center'>
        {imageIsLoading ? (
          <span className='loading loading-spinner loading-sm'></span>
        ) : (
          logoImageUrl && (
            <div className='w-14 h-14 mask mask-squircle'>
              <img src={logoImageUrl} alt={businessName} />
            </div>
          )
        )}
      </div>
      <h3 className='flex text-lg font-bold text-gray-800 items-center justify-center mt-2 mb-4'>{businessName}</h3>
      {businessType && (
        <div className='flex items-center justify-center -mt-4'>
          <img className='w-4 h-4' src={IconMapper(businessType)} alt={businessType} />
          <p className='text-gray-500 text-sm pl-1'>{businessType}</p>
        </div>
      )}
      <div className='flex flex-col'>
        {onViewCampaigns && (
          <button onClick={onViewCampaigns} className='btn btn-primary btn-sm mb-2'>
            View Loyalty Campaigns
          </button>
        )}
        {onViewAboutUsPage && (
          <button onClick={onViewAboutUsPage} className='btn btn-primary btn-sm mb-2'>
            View About Us
          </button>
        )}
        {onNavigate && (
          <button onClick={onNavigate} className='btn btn-secondary btn-sm mb-2'>
            Navigate
          </button>
        )}
        {socialLinks && Object.keys(socialLinks).length > 0 && <MapSocialLinks socialLinks={socialLinks} />}
      </div>
    </div>
  )
}

export default BusinessPopup
