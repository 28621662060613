import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchClientCampaigns, fetchCustomer, joinCampaign, reset, confirmClientGPDR } from '../features/campaign/campaignSlice'

const JoinPartnerCampaign = ({
  clientId,
  clientID,
  referringCustomerID,
  setShowJoinCampaign,
  resetClientData,
  businessName,
  navigate,
  disableNavigation,
}) => {
  const dispatch = useDispatch()
  const { campaigns, isLoading, userDetails, isError } = useSelector((state) => state.campaign)
  const [gpdr, setGpdr] = useState('')
  const [hasJoinedCampaign, setHasJoinedCampaign] = useState(false)

  useEffect(() => {
    if (clientId) {
      console.log('clientId', clientId)
      dispatch(fetchClientCampaigns(clientId)).then(() => dispatch(fetchCustomer()))
      document.getElementById('my-modal-6').checked = true
    } else if (clientID.length === 24 && !referringCustomerID) {
      console.log('clientId length long', clientID)
      dispatch(fetchClientCampaigns(clientID)).then(() => dispatch(fetchCustomer()))
      document.getElementById('my-modal-6').checked = true
    }
  }, [clientID, dispatch, clientId, referringCustomerID])

  const handleJoinCampaign = (campaignID) => {
    dispatch(joinCampaign({ campaignID, referringCustomerID: referringCustomerID || null })).then(() => dispatch(fetchCustomer()))
  }

  useEffect(() => {
    if (isError) {
      dispatch(reset())
    }
  })

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined && Array.isArray(userDetails.campaignsJoined) && userDetails.campaignsJoined.length > 0) {
      setHasJoinedCampaign(true)
    } else {
      setHasJoinedCampaign(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.clients) {
      const client = userDetails.clients.find((c) => c.clientID === (clientId || clientID))
      if (client) {
        setGpdr(client.acceptedGPDR)
      }
    }
  }, [userDetails, clientId, clientID])

  const onChange = (e) => {
    setGpdr(e.target.checked)

    let idToUse
    if (clientID && clientID.length === 24) {
      idToUse = clientID
    } else if (clientId && clientId.length === 24) {
      idToUse = clientId
    }

    if (idToUse) {
      dispatch(
        confirmClientGPDR({
          clientID: idToUse,
          gpdrSelection: e.target.checked,
        })
      )
    } else {
      console.error('No valid idToUse found')
    }
  }

  return (
    <div>
      <input type='checkbox' id='my-modal-6' className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box'>
          {isLoading ? (
            <div className='flex flex-col items-center'>
              <img className='w-5' src='/images/loadingSpinner.svg' alt='Loading' />
              <p className='text-sm'>Loading...</p>
            </div>
          ) : campaigns.length === 0 ? (
            <>
              <p className='font-bold'>{businessName} has no active loyalty campaigns</p>
              <p>Please check back again later</p>
            </>
          ) : (
            <>
              <h1 className='text-lg font-bold mb-4'>
                There are {campaigns.length} loyalty schemes currently available for {campaigns[0]?.businessName}
              </h1>
              {campaigns.map((campaign) => (
                <div key={campaign._id} className='grid grid-cols-1 gap-4 mb-3'>
                  <div className='bg-gray-200 p-4 rounded-lg shadow-lg'>
                    <div className='flex justify-between items-center space-x-2 text-sm'>
                      <div className='text-md font-bold text-left'>{campaign.campaignName}</div>
                      {userDetails && userDetails.campaignsJoined && userDetails.campaignsJoined.some((c) => c.campaignID === campaign._id) ? (
                        <button className='btn' disabled>
                          Joined
                        </button>
                      ) : (
                        <button onClick={() => handleJoinCampaign(campaign._id)} className='btn'>
                          Join
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className='modal-action'>
            {hasJoinedCampaign && campaigns.length > 0 && campaigns[0].businessName ? (
              <label className='cursor-pointer label justify-start'>
                <input
                  type='checkbox'
                  name='marketingConsent'
                  checked={gpdr}
                  onChange={onChange}
                  disabled={!hasJoinedCampaign}
                  className='checkbox checkbox-success align-middle'
                />
                <span className='label-text text-sm font-medium text-gray-700 text-left ml-3'>
                  I consent to receive in app communications & promotions from {campaigns[0].businessName}
                </span>
              </label>
            ) : (
              ''
            )}
            <button
              onClick={() => {
                document.getElementById('my-modal-6').checked = false
                resetClientData()
                setTimeout(() => {
                  if (!disableNavigation) {
                    navigate('/')
                  }
                }, 100)
              }}
              className='btn'>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinPartnerCampaign
