import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import IconMapper, { getCategoryFromBusinessType } from './IconMapper'
import MapSocialLinks from './MapSocialLinks'
import BusinessPopup from './BusinessPopup'
import 'leaflet/dist/leaflet.css'

const PartnersMap = ({
  mapRef,
  containerStyle,
  mapCenter,
  zoomLevel,
  handleBoundsChanged,
  BoundsHandler,
  partners,
  activeCategory,
  handleMarkerClick,
  imageIsLoading,
  logoImageUrl,
  socialLinks,
  viewCampaigns,
  viewAboutUsPage,
  navigateToPartner,
  userLocation,
}) => {
  const createCustomIcon = (businessType) => {
    const iconUrl = IconMapper(businessType)
    return L.divIcon({
      className: 'custom-icon',
      html: `
        <div style="
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        ">
          <img src="${iconUrl}" alt="${businessType}" style="width: 20px; height: 20px;" />
        </div>
      `,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    })
  }

  return (
    <MapContainer
      center={mapCenter}
      style={containerStyle}
      zoom={zoomLevel}
      ref={mapRef}
      whenCreated={(map) => {
        mapRef.current = map
      }}>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <BoundsHandler onBoundsChanged={handleBoundsChanged} />
      {Array.isArray(partners) && partners.length > 0 ? (
        partners.map((partner) => {
          if (!partner || !partner.location || !partner.location.coordinates) {
            return null
          }
          const category = getCategoryFromBusinessType(partner.businessType)
          if (!activeCategory || category === activeCategory) {
            return (
              <Marker
                key={partner._id}
                position={[partner.location.coordinates[1], partner.location.coordinates[0]]}
                icon={createCustomIcon(partner.businessType)}
                eventHandlers={{
                  click: () => handleMarkerClick(partner),
                }}>
                <Popup>
                  <BusinessPopup
                    businessName={partner.businessName}
                    businessType={partner.businessType}
                    logoImageUrl={logoImageUrl}
                    imageIsLoading={imageIsLoading}
                    onViewCampaigns={viewCampaigns}
                    onViewAboutUsPage={viewAboutUsPage}
                    onNavigate={() => navigateToPartner(partner.location.coordinates[1], partner.location.coordinates[0])}
                    socialLinks={socialLinks}
                  />
                </Popup>
              </Marker>
            )
          }
          return null
        })
      ) : (
        <div>No partners to display</div>
      )}
      {userLocation && (
        <Marker
          position={userLocation}
          icon={L.divIcon({
            className: 'custom-icon',
            html: '<div style="background-color: #4285F4; border: 2px solid white; border-radius: 50%; width: 14px; height: 14px;"></div>',
            iconSize: [14, 14],
          })}
        />
      )}
    </MapContainer>
  )
}

export default PartnersMap
