import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import BusinessPopup from './BusinessPopup'

// Fix marker icons
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
})

const containerStyle = {
  width: '100%',
  height: '500px', // You can adjust this height as needed
}

const ClientMap = ({ clientID, socialLinks, logoImageUrl }) => {
  let currentLinks = {}

  if (Array.isArray(socialLinks)) {
    currentLinks = socialLinks.find((links) => links.clientID === clientID) || {}
  }

  console.log(currentLinks, 'current links')

  // Get the partner's location from the currentLinks
  const location = currentLinks.location
  let partnerPosition = [0, 0] // [lat, lng]
  if (location) {
    partnerPosition = location.split(',').map((coord) => parseFloat(coord))
  }

  const mapRef = useRef(null)
  // State for tracking the user's location (replicating behavior from AllPartnersMap)
  const [userLocation, setUserLocation] = useState(null)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([position.coords.latitude, position.coords.longitude])
        },
        (error) => {
          console.error('Error fetching geolocation in ClientMap:', error)
        }
      )
    }
  }, [])

  useEffect(() => {
    if (mapRef.current && location) {
      mapRef.current.flyTo(partnerPosition, 16)
    }
  }, [location, partnerPosition])

  // Define the navigation function similar to AllPartnersMap
  const navigateToPartner = (lat, lng) => {
    if (!userLocation) {
      alert('User location not available.')
      return
    }
    const url = `https://www.openstreetmap.org/directions?engine=osrm_car&route=${userLocation[0]},${userLocation[1]};${lat},${lng}`
    window.open(url, '_blank')
  }

  return (
    <div className='text-center pb-10 pt-8 w-full custom-zindex-less2'>
      {location && (
        <>
          <MapContainer center={partnerPosition} zoom={16} style={containerStyle} ref={mapRef}>
            <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={partnerPosition}>
              <Popup className='p-0'>
                <BusinessPopup
                  businessName={currentLinks.businessName}
                  businessType={currentLinks.businessType}
                  logoImageUrl={logoImageUrl}
                  imageIsLoading={false}
                  onNavigate={() => navigateToPartner(partnerPosition[0], partnerPosition[1])}
                  socialLinks={currentLinks.socialLinks || {}}
                />
              </Popup>
            </Marker>
          </MapContainer>
        </>
      )}
    </div>
  )
}

export default ClientMap
