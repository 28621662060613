import toast from 'react-hot-toast'

export const customToast = {
  success: (message, options) => {
    if (message?.trim()) {
      toast.success(message, options)
    }
  },
  error: (message, options) => {
    if (message?.trim()) {
      toast.error(message, options)
    }
  },
  // Add other toast types as needed
  promise: (promise, messages, options) => {
    if (messages?.loading?.trim() && messages?.success?.trim() && messages?.error?.trim()) {
      return toast.promise(promise, messages, options)
    }
    return promise
  },
}
