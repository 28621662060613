import React from 'react'

const MessageListSkeleton = () => {
  return (
    <div className='p-4 space-y-4 ml-5'>
      {[...Array(5)].map((_, index) => (
        <div key={index} className='animate-pulse space-y-2 p-4 border-b'>
          <div className='flex space-x-4'>
            {/* Image placeholder */}
            <div className='w-16 h-16 bg-gray-200 rounded' />
            {/* Text lines */}
            <div className='flex-1 space-y-2'>
              <div className='h-4 bg-gray-200 rounded w-3/4'></div>
              <div className='h-4 bg-gray-200 rounded w-1/2'></div>
              <div className='h-4 bg-gray-200 rounded w-1/3'></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MessageListSkeleton
