import React from 'react'
import QRreaderAndroid from './QRreaderAndroid'
import QRreaderIOS from './QRreaderIOS'
import QRreaderDesktop from './QRreaderDesktop'

const QRreader = () => {
  const platform = process.env.REACT_APP_PLATFORM
  console.log('🚀 ~ QRreader ~ platform:', platform)

  return platform === 'desktop' ? <QRreaderDesktop disableNavigation /> : platform === 'ios' ? <QRreaderIOS /> : <QRreaderAndroid />
}

export default QRreader
