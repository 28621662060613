import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../features/message/messageSlice'
import MessageItem from './MessageItem'
import { fetchCustomer } from '../../features/campaign/campaignSlice'
import MessageListSkeleton from './MessageListSkeleton'

const MessageList = ({ messages, onListItemClick, setIsLoading, setLastMessageID, isLoading, isInitialLoad }) => {
  const lastMessageRef = useRef(null)
  const dispatch = useDispatch()

  const { filter } = useSelector((state) => state.message)
  const { userDetails } = useSelector((state) => state.campaign)
  const { AllMessagesIsLoading } = useSelector((state) => state.message)

  const filteredMessages = messages.filter((message) => {
    if (filter === 'All') {
      return true
    } else if (filter === 'Messages') {
      return message.contentID.messageType === 'message'
    } else if (filter === 'Events') {
      return message.contentID.status === 'alert'
    }
  })

  useEffect(() => {
    dispatch(fetchCustomer())
  }, [dispatch])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && messages.length > 0) {
        setIsLoading(true)
        setLastMessageID(messages[messages.length - 1]._id)
      }
    })

    let currentLastMessageRef = lastMessageRef.current

    if (currentLastMessageRef) {
      observer.observe(currentLastMessageRef)
    }

    return () => {
      if (currentLastMessageRef) {
        observer.unobserve(currentLastMessageRef)
      }
    }
  }, [messages, setIsLoading, setLastMessageID])

  return (
    <div className='md:w-3/6 border md:mr-5 rounded-md w-full bg-white' style={{ height: '91vh', overflowY: 'auto' }}>
      {/* Buttons and filter */}
      <div className='h-16 border-b flex items-center justify-between'>
        <div className='flex flex-nowrap'>
          <button className='btn btn-sm text-xs px-1 mx-2' onClick={() => dispatch(setFilter('All'))}>
            All
          </button>
          <button className='btn btn-sm text-xs px-1 mr-2' onClick={() => dispatch(setFilter('Messages'))}>
            Messages
          </button>
          <button className='btn btn-sm text-xs px-1' onClick={() => dispatch(setFilter('Events'))}>
            Events
          </button>
        </div>
        <div>
          <button className='btn btn-sm text-xs px-1 mr-2'> Filter</button>
        </div>
      </div>
      <div className='overflow-y-scroll h-full'>
        {AllMessagesIsLoading && isInitialLoad ? (
          <MessageListSkeleton />
        ) : (
          filteredMessages.map((message, index) => (
            <MessageItem
              key={index}
              message={message}
              onListItemClick={onListItemClick}
              lastMessageRef={index === filteredMessages.length - 1 ? lastMessageRef : null}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default MessageList
