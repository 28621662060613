import React from 'react'

const SkeletonCampaign = () => (
  <div className='flex flex-col md:flex-row bg-[#f1f2f5] w-full'>
    {/* Desktop Sidebar Skeleton */}
    <div className='hidden md:block'>
      <ul className='flex sticky top-20 menu md:w-56 mr-4'>
        {[1, 2, 3].map((i) => (
          <li key={i}>
            <div className='animate-pulse-subtle flex flex-col justify-start items-start text-left w-56 shadow p-2 h-20'>
              <div className='flex items-center'>
                <div className='w-10 h-10 mr-2 rounded-full bg-gray-300'></div>
                <div className='flex flex-col w-32'>
                  <div className='h-4 mb-2 bg-gray-300 rounded'></div>
                  <div className='h-3 w-3/4 bg-gray-300 rounded'></div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>

    {/* Mobile Selector Skeleton */}
    <div className='md:hidden flex justify-center mb-6'>
      <div className='h-12 bg-gray-300 w-64 rounded-lg'></div>
    </div>

    {/* Main Content Skeleton */}
    <div className='flex flex-col w-full justify-start'>
      <div className='flex items-center justify-around'>
        <div className='w-24 h-24 bg-gray-300 rounded-full animate-pulse-subtle mb-4'></div>
        <div className='h-12 bg-gray-300 w-48 rounded-full animate-pulse-subtle'></div>
      </div>

      {/* Campaign Cards Skeleton */}
      {[1, 2].map((i) => (
        <div key={i} className='bg-white px-4 rounded-lg shadow-lg pt-8 w-full relative mb-8'>
          <div className='mb-5 flex flex-col items-center'>
            <div className='h-24 w-24 bg-gray-300 rounded-xl animate-pulse-subtle'></div>
            <div className='h-6 bg-gray-300 w-64 mt-4 rounded'></div>
            <div className='h-4 bg-gray-300 w-48 mt-2 rounded'></div>
          </div>

          {/* Stamps Grid Skeleton */}
          <div className='grid grid-flow-row grid-cols-5 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-9 gap-4 justify-items-center mb-4'>
            {[...Array(9)].map((_, i) => (
              <div key={i} className='w-12 h-12 lg:h-16 lg:w-16 bg-gray-300 rounded-md animate-pulse-subtle'></div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default SkeletonCampaign
