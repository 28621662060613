import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { customToast } from '../components/CustomToast'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { register, reset } from '../features/auth/authSlice'
import { setCustomerReferralCode } from '../features/campaign/campaignSlice'
import { isPasswordSecure, getPasswordStrength } from '../utils/passwordUtils'
import Spinner from '../components/Spinner'

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    accessPass: '',
    marketingConsent: false,
    privacyPolicy: false,
    acceptAll: false,
  })
  const [passwordError, setPasswordError] = useState('')
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordMatchError, setPasswordMatchError] = useState('')
  const [utmParams, setUtmParams] = useState({})
  console.log('🚀 ~ Register ~ utmParams:', utmParams)

  const { name, email, password, password2, accessPass, marketingConsent, privacyPolicy } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)
  const { referringClient, customerReferralCode } = useSelector((state) => state.campaign)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const referralCode = params.get('referralCode')

    if (referralCode) {
      dispatch(setCustomerReferralCode(referralCode))
    }

    // Capture UTM parameters
    const utmSource = params.get('utm_source')
    console.log('🚀 ~ useEffect ~ utmSource:', utmSource)
    const utmMedium = params.get('utm_medium')
    console.log('🚀 ~ useEffect ~ utmMedium:', utmMedium)
    const utmCampaign = params.get('utm_campaign')
    console.log('🚀 ~ useEffect ~ utmCampaign:', utmCampaign)
    const utmTerm = params.get('utm_term')
    console.log('🚀 ~ useEffect ~ utmTerm:', utmTerm)
    const utmContent = params.get('utm_content')
    console.log('🚀 ~ useEffect ~ utmContent:', utmContent)

    setUtmParams({
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
    })
  }, [location.search, dispatch])

  useEffect(() => {
    if (isError) {
      customToast.error(message)
    }

    // Redirect when logged in
    if (isSuccess || user) {
      // Check if clientID exists and user is true
      if (referringClient && user) {
        const params = new URLSearchParams()
        params.append('clientId', referringClient)
        navigate('/reader?' + params.toString())
      } else if (customerReferralCode && user) {
        const params = new URLSearchParams()
        params.append('referral', customerReferralCode)
        navigate('/reader?' + params.toString())
      } else if (user && !referringClient && !customerReferralCode) {
        console.log('redirecting to home')
        navigate('/')
      }
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch, referringClient, customerReferralCode])

  const toggleAcceptAll = () => {
    setFormData((prevState) => ({
      ...prevState,
      acceptAll: !prevState.acceptAll,
      marketingConsent: !prevState.acceptAll,
      privacyPolicy: !prevState.acceptAll,
    }))
  }

  const onChange = (e) => {
    const { name, value, type, checked } = e.target
    if (name === 'password') {
      if (!isPasswordSecure(value)) {
        setPasswordError('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      } else {
        setPasswordError('')
      }
      setPasswordStrength(getPasswordStrength(value))
    }

    if (name === 'password2') {
      if (value !== formData.password) {
        setPasswordMatchError('Passwords do not match')
      } else {
        setPasswordMatchError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isPasswordSecure(password)) {
      customToast.error('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      return
    }

    if (password !== password2) {
      customToast.error('Passwords do not match')
    } else {
      const userData = {
        name,
        email,
        password,
        accessPass,
        marketingConsent,
        privacyPolicy,
        ...utmParams, // Include UTM parameters in the user data
      }
      console.log(userData)
      dispatch(register(userData))
    }
  }

  if (isLoading) {
    return <Spinner />
  }
  return (
    <>
      <Helmet>
        <title>Register | Loyalty Club PLC</title>
        <meta property='og:title' content='Join the Loyalty Club and Earn Rewards!' />
        <meta
          property='og:description'
          content='Sign up for the Loyalty Club and start earning rewards today! Use my referral code to get started.'
        />
        <meta property='og:image' content='https://yourwebsite.com/path/to/image.jpg' />
        <meta property='og:url' content={`https://members.loyaltyclubplc.com${location.pathname}${location.search}`} />
        <meta property='og:type' content='website' />
      </Helmet>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col m-3 space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:m-0'>
          {/* Left Side */}
          <div className='p-6 md:p-20'>
            {/* Top Content */}

            <h2 className='font-mono mb-5 text-4xl font-bold text-left ml-1'>Register</h2>

            <p className='max-w-sm mb-12 font-sans font-light text-gray-600 text-left'>Register now to start earning rewards</p>
            <form onSubmit={onSubmit}>
              <div className='relative mb-2'>
                <input type='text' className='global-input' placeholder='' id='name' name='name' value={name} onChange={onChange} required />
                <label htmlFor='name' className='global-form-label'>
                  Your Name
                </label>
              </div>
              <div className='relative mb-2'>
                <input type='email' className='global-input' placeholder='' id='email' name='email' value={email} onChange={onChange} required />
                <label htmlFor='email address' className='global-form-label'>
                  Your email address
                </label>
              </div>
              <div className='relative mb-2'>
                <input
                  type='password'
                  className='global-input'
                  placeholder=''
                  id='password'
                  name='password'
                  value={password}
                  onChange={onChange}
                  required
                />
                <label htmlFor='your password' className='global-form-label'>
                  Your Password
                </label>
              </div>
              {passwordError && <p className='text-red-500 text-xs mt-1'>{passwordError}</p>}
              <div className='mt-1 h-2 w-full bg-gray-200 rounded-full'>
                <div
                  className={`h-full rounded-full ${
                    passwordStrength === 0
                      ? 'bg-red-500'
                      : passwordStrength === 1
                      ? 'bg-orange-500'
                      : passwordStrength === 2
                      ? 'bg-yellow-500'
                      : passwordStrength === 3
                      ? 'bg-lime-500'
                      : passwordStrength === 4
                      ? 'bg-green-500'
                      : 'bg-green-600'
                  }`}
                  style={{ width: `${passwordStrength * 20}%` }}></div>
              </div>
              <p className='text-xs mt-1 mb-2'>
                {passwordStrength === 0
                  ? 'Very weak'
                  : passwordStrength === 1
                  ? 'Weak'
                  : passwordStrength === 2
                  ? 'Fair'
                  : passwordStrength === 3
                  ? 'Good'
                  : passwordStrength === 4
                  ? 'Strong'
                  : 'Very strong'}
              </p>

              <div className='relative mb-2'>
                <input
                  type='password'
                  className='global-input'
                  placeholder=''
                  id='password2'
                  name='password2'
                  value={password2}
                  onChange={onChange}
                  required
                />
                <label htmlFor='confirm password' className='global-form-label'>
                  Confirm your password
                </label>
                {passwordMatchError && <p className='text-red-500 text-xs mt-1'>{passwordMatchError}</p>}
              </div>
              <div className='relative mb-2'>
                <input
                  type='password'
                  className='global-input'
                  placeholder=''
                  id='accessPass'
                  name='accessPass'
                  value={accessPass}
                  onChange={onChange}
                  required
                />
                <label htmlFor='access password' className='global-form-label'>
                  Access Password
                </label>
              </div>

              <label className='cursor-pointer label justify-start'>
                <input
                  type='checkbox'
                  name='privacyPolicy'
                  checked={formData.privacyPolicy}
                  onChange={onChange}
                  className='checkbox checkbox-success'
                  required
                />
                <span className='ml-3 label-text text-sm font-medium text-gray-700 text-left'>
                  I confirm that I have read and agree to the Privacy Policy
                </span>
              </label>

              <label className='cursor-pointer label justify-start'>
                <input
                  type='checkbox'
                  name='marketingConsent'
                  checked={formData.marketingConsent}
                  onChange={onChange}
                  className='checkbox checkbox-success align-middle'
                />
                <span className='label-text text-sm font-medium text-gray-700 text-left ml-3'>
                  I consent to receive marketing communications from you.
                </span>
              </label>
              <label className='cursor-pointer label justify-start'>
                <input type='checkbox' checked={formData.acceptAll} onChange={toggleAcceptAll} className='checkbox checkbox-success align-middle' />
                <span className='label-text text-sm font-bold text-gray-700 text-left ml-3 '>Accept all</span>
              </label>

              {/* Middle Content */}
              <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
                <button className='flex p-6 bg-blue-500 rounded-md text-white w-full   justify-center items-center space-x-4 font-sans font-bold text-white  shadow-lg px-9 bg-cyan-700 shadow-cyan-100 hover:bg-opacity-90 shadow-sm hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
                  <span>Submit</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
              </div>
            </form>
          </div>
          {/* Right Side */}
          <img src='images/image.jpg' alt='' className='w-[430px] hidden md:block' />
        </div>
      </div>
    </>
  )
}

export default Register
