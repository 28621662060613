import React from 'react'
import SocialLinks from '../components/SocialLinks'
import ClientMap from '../components/ClientMap'

const About = ({ clientID, socialLinks, profileDetails, userDetails, logoUrl, businessName, isFromMap = false }) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  return (
    <div className='bg-white p-4 rounded-lg mt-1 mb-10 mt-10'>
      <div className='flex w-full flex-col'>
        {isFromMap && (
          <div className='flex flex-col items-center mb-6'>
            <h2 className='text-3xl font-bold mb-4'>About {businessName || 'Us'}</h2>
            {logoUrl && (
              <div className='w-24 h-24 mb-4 mt-2'>
                <img src={logoUrl} alt={`${businessName} Logo`} className='w-full h-full object-contain mask mask-squircle' />
              </div>
            )}
          </div>
        )}

        {profileDetails.aboutYou && <div className='w-full text-justify max-w-none' dangerouslySetInnerHTML={{ __html: profileDetails.aboutYou }} />}
        {socialLinks[0]?.location ? <ClientMap logoImageUrl={logoUrl} clientID={clientID} socialLinks={socialLinks} /> : null}
        {profileDetails.selectedHours && (
          <div className='flex flex-col items-center pb-10 mt-8'>
            <h3 className='text-2xl font-bold mb-4'>Opening Hours</h3>
            <div className='flex'>
              <div className='flex flex-col'>
                {daysOfWeek.map((day) => (
                  <div key={day} className='w-24 text-left font-bold'>
                    {day}
                  </div>
                ))}
              </div>
              <div className='mx-4 border-l border-gray-400'></div>
              <div className='flex flex-col'>
                {daysOfWeek.map((day) => (
                  <div key={day} className='flex items-start'>
                    {profileDetails.selectedHours[day] && profileDetails.selectedHours[day].length > 0 ? (
                      profileDetails.selectedHours[day].map((range, index) => (
                        <div key={index} className='mr-2'>
                          {range.opening && range.closing ? `${range.opening} - ${range.closing}` : 'Closed'}
                        </div>
                      ))
                    ) : (
                      <div>Closed</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {profileDetails.priceRange && (
          <div className='flex my-8'>
            <p className='mr-2 font-bold'>Price Range:</p>
            <p>{profileDetails.priceRange}</p>
          </div>
        )}
        {profileDetails.moreDetails && (
          <div className='w-full text-justify max-w-none' dangerouslySetInnerHTML={{ __html: profileDetails.moreDetails }} />
        )}

        <div className='mt-8'>{socialLinks ? <SocialLinks clientID={clientID} socialLinks={socialLinks} /> : null}</div>
      </div>
    </div>
  )
}

export default About
